import React, { PureComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Image, Video, Clipboard, BarChart2 } from "react-feather";
import Axios from "axios";
import "chart.js";
import { AreaChart } from "react-chartkick";
import { Notification } from "react-notification";
import { getCovertedAnalyticsDuration } from "../../components/helper";
import Spinner from "../../components/Spinner";
import { server, config, upload_path } from "../../routes/env";
import Modal from "../../components/modal";
import hideModal from "../../components/HideModal";
import resetForm from "../../components/ResetForm";

function Previews(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.pdf
      ? "image/png, image/jpeg, image/jpg, video/mp4, application/pdf"
      : "image/png, image/jpeg, image/jpg, video/mp4",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file, index) => (
    <div className="col-md-3" key={index}>
      <div
        className="card text-center rounded-custom"
        style={{ position: "relative", width: "100%" }}
      >
        {file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/png" ? (
          <img
            src={file.preview}
            className="wilyer_file_uploading rounded-custom"
            alt="preview"
          />
        ) : file.type === "video/mp4" ? (
          <video controls className="wilyer_file_uploading rounded-custom">
            <source src={file.preview} />
          </video>
        ) : (
          <img
            src="/assets/img/banners/1.png"
            className="wilyer_file_uploading rounded-custom"
            alt="preview"
          />
        )}
        <div className="progress upload_progress_bar">
          <div
            className="progress-bar bg-info progress-bar-striped progress-bar-animated"
            role="progressbar"
            id={"file_" + index}
            style={{ width: "0%" }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            0%
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container p-4">
      <label className="form-control">
        <div {...getRootProps({ className: "dropzone" })}>
          <input name="file" {...getInputProps()} multiple={true} />
        </div>
        <span>Select files to upload</span>
      </label>

      <div className="row g-4 mt-3">{thumbs}</div>
    </section>
  );
}

export default class Library extends PureComponent {
  state = {
    content: [],
    url: "/signage/content/read",
    curr_url: "",

    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    show_more_button: "Show more",
    next: null,
    preview_file_type: "",
    preview_file_name: "",
    preview_file_path: "",

    filter: "banner_and_clips",
    render_files: [],

    uplaod_button_loader: "",

    message: "",
    notification: false,
    loader: "",

    scroller_data: "",

    analytics_id: null,
    analytics_data: [],
    analytics_preview: false,
    total_analytics_duration: 0,
    analytics_filter_days: 0,
    total_analytics_impressions: 0,

    // Update Web URL
    weburl_id: "",
    weburl_name: "",
    weburl_url: "",

    // PDF access
    pdf_access: false,

    color: "",
    font_size: "",
    bg_color: "",
    scroller: "",
  };

  componentDidMount = async () => {
    this.read_content(this.state.url);
    document.title = "Content Library - Signage Cloud";

    Axios.get(server + "/account/access", config).then((rsp) => {
      this.setState({
        pdf_access: rsp.data.status,
      });
    });
  };

  read_content = async (url, search = null, fromUpload = false) => {
    this.setState({
      show_more_button: this.state.spinner,
      curr_url: url,
    });

    // if read_content function called from upload funcation then set filter to banners and clips only
    if (fromUpload) {
      this.setState({
        filter: "banner_and_clips",
      });
    }

    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        content: search
          ? rsp.data.results
          : this.state.content.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  filter = async (filter) => {
    // event.persist();
    await this.setState({
      content: [],
      filter: filter,
    });

    if (filter === "banner_and_clips") {
      this.read_content(this.state.url, true);
    } else if (filter === "text") {
      this.read_content("/signage/content/text/read", true);
    } else {
      this.read_content("/signage/content/web/read", true);
    }
  };

  search = (event) => {
    if (this.state.filter === "text") {
      this.read_content(
        "/signage/content/text/read?search=" + event.target.value,
        true
      );
    } else {
      this.read_content(this.state.url + "?search=" + event.target.value, true);
    }
  };

  progress = (progressEvent, location) => {
    var dom = document.getElementById("file_" + String(location));
    dom.style.width = (progressEvent.loaded * 100) / progressEvent.total + "%";
    dom.innerText =
      ((progressEvent.loaded * 100) / progressEvent.total).toFixed() + "%";
  };

  upload = async (event) => {
    event.preventDefault();
    event.persist();

    for (var i = 0; i < event.target.file.files.length; i++) {
      var progressEvent = {
        loaded: 0,
        total: 100,
      };
      this.progress(progressEvent, i);
    }

    this.setState({
      uplaod_button_loader: <Spinner />,
    });

    for (i = 0; i < event.target.file.files.length; i++) {
      var config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => this.progress(progressEvent, i),
      };
      var formData = new FormData();
      formData.append("file", event.target.file.files[i]);
      await Axios.post(
        upload_path + "/signage/content/create",
        formData,
        config
      )
        .then((rsp) => {
          this.setState({
            message: rsp.data.detail,
            notification: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    this.read_content(this.state.url, true, true);
    hideModal(1);
    resetForm(1);
    this.setState({
      uplaod_button_loader: "",
    });
  };

  delete_content = (id, dom_id) => {
    document.getElementById(dom_id).classList.add("d-none");
    Axios.delete(server + "/signage/content/delete/" + id + "/", config)
      .then((rsp) => {
        this.setState({
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  create_scroller = async (event) => {
    event.preventDefault();
    event.persist();
    this.setState({
      loader: <Spinner />,
    });

    const params = {
      title: event.target.title.value,
      color: event.target.color.value,
      bg_color: event.target.bg_color.value,
      content: event.target.content.value,
      font_size: event.target.font_size.value,
    };

    Axios.post(server + "/signage/content/text/create", params, config)
      .then((rsp) => {
        this.read_content("/signage/content/text/read", true);
        hideModal(0);
        resetForm(0);
        this.setState({
          filter: "text",
          message: rsp.data.detail,
          notification: true,
          loader: "",
        });
      })

      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  delete_scroller = async (id, dom_id) => {
    document.getElementById(dom_id).classList.add("d-none");
    Axios.delete(server + "/signage/content/text/delete/" + id + "/", config)
      .then((rsp) => {
        this.setState({
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  scroller_data = (id) => {
    this.setState({
      scroller_data: this.state.content.filter((data) => data.id === id)[0],
    });
  };

  update_scroller = async (event) => {
    event.preventDefault();
    event.persist();
    this.setState({
      loader: <Spinner />,
    });

    const params = {
      id: this.state.scroller_data.id,
      title: event.target.title.value,
      color: event.target.color.value,
      bg_color: event.target.bg_color.value,
      content: event.target.content.value,
      font_size: event.target.font_size.value,
    };

    Axios.put(server + "/signage/content/text/update", params, config)
      .then((rsp) => {
        this.read_content("/signage/content/text/read", true);
        hideModal(2);
        resetForm(2);
        this.setState({
          scroller_data: "",
          filter: "text",
          message: rsp.data.detail,
          notification: true,
          loader: "",
        });
      })

      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
          loader: "",
        });
      });
  };

  analytics = async (preview_file_name, analytics_id) => {
    this.setState({
      analytics_preview: false,
    });
    await this.setState({
      analytics_id,
      preview_file_name,
    });
    this.readAnalytics();
  };

  readAnalytics = (type = "tw") => {
    // Reading Analytics
    Axios.get(
      server +
        "/signage/analytics/content/" +
        this.state.analytics_id +
        "/" +
        type,
      config
    ).then((rsp) => {
      this.setState({
        analytics_data: rsp.data.impressions,
        total_analytics_duration: getCovertedAnalyticsDuration(
          rsp.data.duration
        ),
        total_analytics_impressions: rsp.data.total_impressions,
        analytics_preview: true,
        analytics_filter_days: type === "tw" ? 7 : 30,
      });
    });
  };

  // WEB URL INTEGRATIONs
  // CREATE
  createWebURL = (e) => {
    e.preventDefault();
    this.setState({
      loader: <Spinner />,
      message: "",
      filter: "url",
    });

    const params = {
      name: e.target.name.value,
      url: e.target.url.value,
    };

    Axios.post(server + "/signage/content/web/create", params, config)
      .then((rsp) => {
        this.read_content("/signage/content/web/read", true);
        this.setState({
          loader: "",
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            message: err.response.data.detail,
            notification: true,
          });
        }
        this.setState({
          loader: "",
        });
      });
  };

  updateWebURL = async (e) => {
    e.preventDefault();
    this.setState({
      loader: <Spinner />,
      message: "",
    });

    const params = {
      name: e.target.weburl_name.value,
      url: e.target.weburl_url.value,
    };

    await Axios.put(
      server + "/signage/content/web/update/" + this.state.weburl_id,
      params,
      config
    )
      .then((rsp) => {
        this.read_content(this.state.curr_url, true);
        this.setState({
          loader: "",
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            message: err.response.data.detail,
            notification: true,
          });
        }
        this.setState({
          loader: "",
        });
      });
  };

  deleteWebURL = async (id) => {
    this.setState({
      content: this.state.content.filter((data) => data.id !== id),
    });

    Axios.delete(server + "/signage/content/web/delete/" + id, config)
      .then((rsp) => {
        this.setState({
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  render() {
    const {
      content,
      show_more_button,
      next,
      preview_file_name,
      preview_file_type,
      preview_file_path,
      uplaod_button_loader,
      filter,
      message,
      notification,
      scroller_data,
      loader,
      analytics_data,
      analytics_preview,

      total_analytics_duration,
      analytics_filter_days,
      total_analytics_impressions,
      pdf_access,

      color,
      font_size,
      bg_color,
      scroller,
    } = this.state;

    const { weburl_name, weburl_url } = this.state;
    const { filter_type } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={5000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4 shadow-sm">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Content Library</h3>
            </div>
          </div>
        </div>

        {/* Search and Add New data buttons */}
        <div className="row  justify-content-between mt-4 mb-0">
          <div className="col-12 my-2 text-right">
            <button
              className="btn btn-primary mr-2 mt-1 rounded-custom shadow-sm px-3"
              data-toggle="modal"
              data-target="#add_clips_or_banners_modal"
            >
              Add Media {pdf_access ? "/PDF" : ""}
            </button>
            <button
              className="btn btn-primary mr-2 mt-1 rounded-custom shadow-sm px-3"
              data-toggle="modal"
              data-target="#add_text_scroller_modal"
            >
              Add Scrollers
            </button>
            {pdf_access ? (
              <button
                className="btn btn-primary mt-1 rounded-custom shadow-sm px-3"
                onClick={() => this.setState({ message: "" })}
                data-toggle="modal"
                data-target="#addURL"
              >
                Add URL
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Filter Buttons */}
        <div className="col-12 pb-2 mt-5">
          {/* <div className="card mt-4"> */}
          {/* <div className="card-body p-3"> */}
          <div className="text-center">
            <div className="d-inline-block mr-2">
              <input
                type="text"
                className="form-control text-muted rounded-custom pl-3"
                // style={{ maxWidth: "300px" }}
                placeholder="Search name"
                onChange={this.search}
              />
            </div>
            <button
              onClick={() => this.filter("banner_and_clips")}
              className={
                filter === "banner_and_clips"
                  ? "btn btn-primary mr-2 mt-1 rounded-custom px-3 shadow-sm"
                  : "btn btn-white border mr-2 mt-1 rounded-custom px-3 shadow-sm"
              }
            >
              Banners/Clips{pdf_access ? "/Pdf" : ""}
            </button>
            <button
              onClick={() => this.filter("text")}
              className={
                filter === "text"
                  ? "btn btn-primary mr-2 mt-1 rounded-custom px-3 shadow-sm"
                  : "btn btn-white border mr-2 mt-1 rounded-custom px-3 shadow-sm"
              }
            >
              Text Scrollers
            </button>
            {pdf_access ? (
              <button
                onClick={() => this.filter("URL")}
                className={
                  filter === "URL"
                    ? "btn btn-primary mr-2 mt-1 rounded-custom px-3 shadow-sm"
                    : "btn btn-white border mr-2 mt-1 rounded-custom px-3 shadow-sm"
                }
              >
                Web URLs
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          {filter === "banner_and_clips"
            ? content.map((data, index) => (
                <div className="col-md-3" key={index} id={"data_" + index}>
                  <div className="card mt-4">
                    <Link to="#">
                      <img
                        src={
                          data.type === "image"
                            ? data.thumbnail
                            : data.type === "video"
                            ? "/assets/img/banners/3.png"
                            : "/assets/img/banners/1.png"
                        }
                        data-toggle="modal"
                        data-target="#view_file_modal"
                        onClick={() =>
                          this.setState({
                            preview_file_name: data.name,
                            preview_file_type: data.type,
                            preview_file_path: data.url,
                          })
                        }
                        alt=""
                        className="card-img-top wilyer_library_card_cover"
                      />
                    </Link>
                    <div className="card-body row gy-2">
                      <div className="col-12">
                        <strong>{data.name}</strong> <br />
                        <small className="text-muted">
                          {data.type === "image" ? (
                            <span>
                              <Image /> Image
                            </span>
                          ) : data.type === "video" ? (
                            <span>
                              <Video /> Video
                            </span>
                          ) : (
                            <span>
                              <Clipboard /> PDF
                            </span>
                          )}
                          {" | "}
                          <Link
                            to="#"
                            className="text-decoration-none"
                            data-toggle="modal"
                            data-target="#analytics"
                            onClick={() => this.analytics(data.name, data.id)}
                          >
                            <span>
                              <BarChart2 /> Impressions
                            </span>
                          </Link>
                        </small>
                      </div>
                      <div className="col-12 mt-3">
                        <button
                          className="btn  btn-primary rounded-custom px-2 mr-1 mb-1"
                          data-toggle="modal"
                          data-target="#view_file_modal"
                          onClick={() =>
                            this.setState({
                              preview_file_name: data.name,
                              preview_file_type: data.type,
                              preview_file_path: data.url,
                            })
                          }
                        >
                          View
                        </button>
                        <button
                          className="btn  btn-danger rounded-custom px-2 mb-1 mr-1"
                          onClick={() =>
                            this.delete_content(data.id, "data_" + index)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : filter === "text"
            ? content.map((data, index) => (
                <div className="col-md-4" key={index} id={"scroller_" + index}>
                  <div className="card mt-4">
                    <div className="card-body">
                      <h6 className="text-muted pt-2">{data.title}</h6>
                      <marquee
                        key={"scroller" + index}
                        className="rounded-lg"
                        style={{
                          color: data.color,
                          fontSize: data.font_size + "px",
                          backgroundColor: data.bg_color,
                        }}
                      >
                        {data.content}
                      </marquee>

                      <div className="float-right">
                        <button
                          className="btn  btn-warning rounded-custom px-2 mr-1 mt-1"
                          data-toggle="modal"
                          data-target="#edit_scroller"
                          onClick={() => this.scroller_data(data.id)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn  btn-danger rounded-custom px-2 mt-1"
                          onClick={() =>
                            this.delete_scroller(data.id, "scroller_" + index)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : content.map((data, index) => (
                <div className="col-md-3" key={index} id={"url_" + index}>
                  <div className="card mt-4">
                    <Link to="#">
                      <img
                        src="/assets/img/banners/2.png"
                        data-toggle="modal"
                        data-target="#view_file_modal"
                        onClick={() =>
                          this.setState({
                            preview_file_name: data.name,
                            preview_file_type: "url",
                            preview_file_path: data.url,
                          })
                        }
                        alt=""
                        className="card-img-top wilyer_library_card_cover"
                      />
                    </Link>
                    <div className="card-body">
                      <h6 className="text-muted pt-2">{data.name}</h6>
                      <a href={data.url}>{data.url}</a>
                      <div className="float-right">
                        <button
                          className="btn  btn-warning rounded-custom px-2 mr-1 mt-1"
                          data-toggle="modal"
                          data-target="#updateURL"
                          onClick={() =>
                            this.setState({
                              weburl_name: data.name,
                              weburl_url: data.url,
                              weburl_id: data.id,
                            })
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="btn  btn-danger rounded-custom px-2 mt-1"
                          onClick={() =>
                            this.deleteWebURL(data.id, "url_" + index)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          <div className="col-12 text-center mt-5 mb-5">
            <button
              className="btn btn-white rounded-custom border px-3"
              onClick={next == null ? null : () => this.read_content(next)}
            >
              {show_more_button}
            </button>
          </div>
        </div>

        {/* Add text scroller modal */}
        <Modal
          key="add_text_scroller_modal"
          id="add_text_scroller_modal"
          title="Add Text Scroller"
        >
          <form action="" onSubmit={this.create_scroller}>
            <div className="modal-body p-4">
              <div className="row g-3 mb-4">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="color">Text Color</label>
                    <input
                      type="color"
                      name="color"
                      defaultValue="#FFFFFF"
                      className="form-control rounded-custom"
                      onChange={(e) =>
                        this.setState({
                          color: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="bg_color">Background Color</label>
                    <input
                      type="color"
                      name="bg_color"
                      className="form-control rounded-custom"
                      onChange={(e) =>
                        this.setState({
                          bg_color: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="bg_color">Font Size</label>
                    <input
                      type="number"
                      name="font_size"
                      className="form-control rounded-custom"
                      onChange={(e) =>
                        this.setState({
                          font_size: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control rounded-custom"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Text Scroller</label>
                <textarea
                  name="content"
                  cols="30"
                  rows="5"
                  className="form-control"
                  onChange={(e) =>
                    this.setState({
                      scroller: e.target.value,
                    })
                  }
                  required
                ></textarea>
              </div>
              <div className="form-group mt-3">
                <marquee
                  className="rounded-lg"
                  key={"new_scroller"}
                  style={{
                    color: color,
                    fontSize: font_size + "px",
                    backgroundColor: bg_color,
                  }}
                >
                  {scroller}
                </marquee>
              </div>
            </div>
            <div className="modal-footer border-0 pb-4">
              <button
                type="submit"
                className="btn btn-primary rounded-custom pl-3 pr-3"
              >
                Add Scroller {loader}
              </button>

              <button
                type="reset"
                className="btn btn-danger  lift rounded-custom pl-3 pr-3"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </Modal>

        {/* Upload Clips/Banners */}
        <Modal
          key="add_clips_or_banners_modal"
          className="modal-lg"
          id="add_clips_or_banners_modal"
          title="Upload Clips/Banners"
        >
          <form action="" onSubmit={this.upload}>
            <Previews pdf={pdf_access} />
            <div className="text-right p-3">
              <button
                type="submit"
                className="btn btn-primary  px-3 rounded-custom mr-1"
              >
                Upload {uplaod_button_loader}
              </button>
              <button
                type="submit"
                className="btn btn-danger  px-3 rounded-custom"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </Modal>

        {/* Edit Scroller */}
        <Modal
          key="edit_scroller"
          id="edit_scroller"
          title="Update Scroller"
          className="modal-lg"
        >
          {scroller_data !== "" ? (
            <form action="" onSubmit={this.update_scroller}>
              <div className="modal-body p-4">
                <div className="row g-3 mb-4">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="color">Text Color</label>
                      <input
                        type="color"
                        name="color"
                        defaultValue={scroller_data.color}
                        className="form-control rounded-custom"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="bg_color">Background Color</label>
                      <input
                        type="color"
                        name="bg_color"
                        defaultValue={scroller_data.bg_color}
                        className="form-control rounded-custom"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="bg_color">Font Size</label>
                      <input
                        type="number"
                        name="font_size"
                        defaultValue={scroller_data.font_size}
                        className="form-control rounded-custom"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    name="title"
                    defaultValue={scroller_data.title}
                    className="form-control rounded-custom"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="content">Text Scroller</label>
                  <textarea
                    name="content"
                    cols="30"
                    rows="5"
                    className="form-control"
                    defaultValue={scroller_data.content}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer border-0 pb-4">
                <button
                  type="submit"
                  className="btn btn-primary rounded-custom pl-3 pr-3"
                >
                  Update Scroller {loader}
                </button>

                <button
                  type="reset"
                  className="btn btn-danger  lift rounded-custom pl-3 pr-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          ) : (
            ""
          )}
        </Modal>

        {/* View File Preview */}
        <Modal
          key="view_file_modal"
          id="view_file_modal"
          title={preview_file_name.substr(0, 40)}
          className="modal-xl"
        >
          <div className="modal-body text-center p-4 mb-3">
            <div className="mt-4">
              {preview_file_type === "image" ? (
                <img
                  src={preview_file_path}
                  key={preview_file_path}
                  alt={preview_file_name}
                  className="playlist_preview_image"
                />
              ) : preview_file_type === "video" ? (
                <video
                  controls
                  key={preview_file_path}
                  className="playlist_preview_image"
                >
                  <source src={preview_file_path} />
                </video>
              ) : preview_file_type === "pdf" ? (
                <iframe
                  className="iframe"
                  style={{ height: "600px", width: "100%" }}
                  title={preview_file_name}
                  key={preview_file_path}
                  src={preview_file_path}
                />
              ) : (
                <iframe
                  className="iframe"
                  style={{ height: "600px", width: "100%" }}
                  title={preview_file_name}
                  key={preview_file_path}
                  src={preview_file_path}
                />
              )}
            </div>
          </div>
        </Modal>

        {/* Analytics */}
        <Modal
          id="analytics"
          title={preview_file_name + " - Analytics"}
          className="modal-lg-2"
        >
          <div className="modal-body mb-4">
            <div className="card-body">
              <div className="card-header bg-transparent border-0 p-3">
                <div className="card-title h5 text-muted d-inline-block"></div>
                <div className="row gy-3 float-right">
                  <div className="col-md-4">
                    <select
                      name="rotation_config"
                      id="rotation_config"
                      className="form-control-sm rounded-custom  btn-primary my-2 mr-2"
                      onChange={(event) =>
                        this.readAnalytics(event.target.value)
                      }
                    >
                      <option value="tw">This Week</option>
                      <option value="tm">This Month</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body text-center">
                <div className={analytics_preview ? "d-none" : ""}>
                  <Spinner size={20} color="#888" />
                </div>
                <div className={analytics_preview ? "row mb-5" : "d-none"}>
                  <div className="col-lg-4 mt-3">
                    <div className="card border">
                      <div className="card-body">
                        Total Impressions: {total_analytics_impressions}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mt-3">
                    <div className="card border">
                      <div className="card-body">
                        Time: Last {analytics_filter_days} Days
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mt-3">
                    <div className="card border">
                      <div className="card-body">
                        Content Play: {total_analytics_duration}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={analytics_preview ? "" : "d-none"}>
                  <AreaChart data={analytics_data} />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* WEB URL */}
        {/* Add New URL */}
        <Modal key="addURL" id="addURL" title="Add New URL">
          <div className="modal-body p-4">
            <form onSubmit={this.createWebURL}>
              {message}
              <div className="form-group">
                <label htmlFor="name">Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="url">URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  required
                />
              </div>
              <div className="modal-footer border-0 mt-3 pr-0">
                <button
                  type="submit"
                  className="btn btn-primary rounded-custom pl-3 pr-3"
                >
                  Add URL {loader}
                </button>

                <button
                  type="reset"
                  id="close1"
                  className="btn btn-danger text-white lift rounded-custom pl-3 pr-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* Edit URL */}
        <Modal key="updateURL" id="updateURL" title="Update URL">
          <div className="modal-body p-4">
            <form onSubmit={this.updateWebURL}>
              <div className="form-group">
                <label htmlFor="name">Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="weburl_name"
                  value={weburl_name}
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="url">URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="weburl_url"
                  value={weburl_url}
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  required
                />
              </div>
              <div className="modal-footer border-0 mt-3 pr-0">
                <button
                  type="submit"
                  className="btn btn-primary rounded-custom pl-3 pr-3"
                >
                  Update URL {loader}
                </button>

                <button
                  type="reset"
                  id="close1"
                  className="btn btn-danger text-white lift rounded-custom pl-3 pr-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </main>
    );
  }
}
