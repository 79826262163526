import React, { PureComponent } from "react";

export default class Help extends PureComponent {
  componentDidMount = () => {
    document.title = "Help -  Signage Cloud";
  };
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Help</h3>
          </div>
        </div>
      </main>
    );
  }
}
