var mode,
  logo,
  server,
  auth,
  upload_path,
  url = window.location.href;

if (url.includes("localhost")) {
  server = "http://localhost:8000";
  auth = "http://localhost:3000";
  upload_path = "http://localhost:8000";
  mode = "wilyer";
  logo = "signage-logo.png";
} else {
  server = "https://api.wilyersignage.com";
  upload_path = "https://api.fastgrow.org";
  if (url.includes("signagecloud")) {
    auth = "https://auth.signagecloud.in";
    mode = "whitelabel";
    logo = "signage-cloud-logo.png";
  } else {
    auth = "https://auth.wilyersignage.com";
    mode = "wilyer";
    logo = "signage-logo.png";
  }
}

module.exports = {
  server,
  auth,
  upload_path,

  mode,
  logo,

  config: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  },
};
