import React from "react";
import { ClassicSpinner } from "react-spinners-kit";
const Spinner = (props) => {
  return (
    <ClassicSpinner
      size={props.size === undefined ? 13 : props.size}
      className="d-inline-block"
      color={props.color === undefined ? "#fff" : props.color}
      loading={true}
    />
  );
};

export default Spinner;
