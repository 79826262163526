import React, { PureComponent } from "react";
import { Image, Clipboard, Video, Link, Underline } from "react-feather";
import Axios from "axios";
import Spinner from "../../components/Spinner";
import { server, config } from "../../routes/env";
import { Notification } from "react-notification";
import Modal from "../../components/modal";
import { PDFViewer } from "react-view-pdf";

export default class PlaylistSettings extends PureComponent {
  state = {
    playlist_id: this.props.match.params.playlist_id,
    is_playlist_loaded: false,
    playlist: [],
    playlist_content: [],
    all_content: [],
    backup_content: [],
    filter: "all",

    loader: "",
    message: "",
    notification: false,

    preview_file_type: "",
    preview_file_name: "",
    preview_file_path: "",

    // PDF access
    pdf_access: false,
  };

  componentDidMount = async () => {
    document.title = "Playlist Settings - Signage Cloud";

    Axios.get(server + "/account/access", config).then((rsp) => {
      this.setState({
        pdf_access: rsp.data.status,
      });
    });

    await Axios.get(server + "/signage/content/read?records_all", config).then(
      (rsp) => {
        this.setState({
          all_content: rsp.data.content,
          backup_content: rsp.data.content,
        });
      }
    );

    await Axios.get(
      server + "/signage/content/web/read?records_all",
      config
    ).then((rsp) => {
      this.setState({
        all_content: this.state.all_content.concat(rsp.data.content),
        backup_content: this.state.backup_content.concat(rsp.data.content),
      });
    });

    await Axios.get(
      server + "/signage/playlist/read/" + this.state.playlist_id + "/",
      config
    ).then((rsp) => {
      this.setState({
        playlist: rsp.data.playlist,
        playlist_content: rsp.data.playlist_content,
        is_playlist_loaded: true,
      });
    });
  };

  add = (id) => {
    var all_content = this.state.all_content;
    var playlist_content = this.state.playlist_content;
    var content = all_content.filter((data) => data.id === id)[0];
    this.setState({
      playlist_content: playlist_content.concat({
        content: content.type !== undefined ? content : null,
        web: content.type === undefined ? content : null,
      }),
      all_content: all_content.filter((data) => data.id !== id),
    });
  };

  remove = (id) => {
    var all_content = [];
    var playlist_content = this.state.playlist_content;

    this.state.all_content.forEach((data) => {
      if (
        playlist_content.filter((content) =>
          content.content === null
            ? content.web.id === data.id
            : content.content.id === data.id
        ).length === 0
      ) {
        all_content = all_content.concat(data);
      }
    });

    this.setState({
      playlist_content: playlist_content.filter((data) =>
        data.content !== null ? data.content.id !== id : data.web.id !== id
      ),
      all_content: all_content.concat(
        playlist_content.filter((data) =>
          data.content !== null ? data.content.id === id : data.web.id === id
        )[0].content ||
          playlist_content.filter((data) =>
            data.content !== null ? data.content.id === id : data.web.id === id
          )[0].web
      ),
    });
  };

  update = async (event) => {
    event.preventDefault();
    this.setState({
      loader: <Spinner />,
    });

    var content = [];

    this.state.playlist_content.forEach((data) => {
      content.push(data.content !== null ? data.content.id : data.web.id);
    });

    var params = {
      id: this.state.playlist_id,
      name: event.target.playlist_name.value,
      duration: event.target.duration.value,
      pdf_duration: this.state.pdf_access
        ? event.target.pdf_duration.value
        : 10,
      content: content,
    };

    Axios.put(server + "/signage/playlist/update", params, config)
      .then((rsp) => {
        this.setState({
          loader: "",
          notification: true,
          message: rsp.data.detail,
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  search = (e) => {
    this.setState({
      all_content: this.state.backup_content.filter((data) =>
        data.name.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    });
  };

  filter = (filter) => {
    this.setState({
      filter,
    });
    if (filter === "media") {
      this.setState({
        all_content: this.state.backup_content.filter(
          (data) => data.type === "image" || data.type === "video"
        ),
      });
    } else if (filter === "pdf") {
      this.setState({
        all_content: this.state.backup_content.filter(
          (data) => data.type === "pdf"
        ),
      });
    } else if (filter === "url") {
      this.setState({
        all_content: this.state.backup_content.filter(
          (data) => data.type === undefined
        ),
      });
    } else {
      this.setState({
        all_content: this.state.backup_content,
      });
    }
  };

  render() {
    const {
      is_playlist_loaded,
      playlist,
      playlist_content,
      message,
      notification,
      loader,
      preview_file_name,
      preview_file_type,
      preview_file_path,
      filter,
      pdf_access,
    } = this.state;

    var all_content = [];
    this.state.all_content.forEach((data) => {
      if (
        playlist_content.filter((content) =>
          content.content === null
            ? content.web.id === data.id
            : content.content.id === data.id
        ).length === 0
      ) {
        all_content = all_content.concat(data);
      }
    });

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={3000}
          onDismiss={() => this.setState({ notification: false })}
        />

        <div
          className={
            "card mx-auto p-4 w-25 " + (is_playlist_loaded ? "d-none" : "")
          }
        >
          <div className="row justify-content-center">
            <Spinner size={20} color={"#555"} />
          </div>
        </div>

        <div
          className={is_playlist_loaded ? "card shadow-sm mt-4 pl-3" : "d-none"}
        >
          <div className="d-flex justify-content-between py-3">
            <h3 className="card-title h5 mb-0 text-capitalize">
              {playlist.name}
            </h3>
          </div>
        </div>
        <form
          className={is_playlist_loaded ? "" : "d-none"}
          onSubmit={this.update}
        >
          <div className="row mb-4 row-eq-height">
            <div className="col-lg-4">
              <div className="card mt-4">
                <div className="card-header text-center bg-transparent p-3">
                  <h6 className="pb-0 mb-0">Playlist Settings</h6>
                </div>
                <div className="card-body pt-2 pb-4">
                  <div className="form-group pt-4">
                    <label htmlFor="duration">
                      Banner duration (in seconds)
                    </label>
                    {is_playlist_loaded ? (
                      <input
                        type="number"
                        name="duration"
                        id="duration"
                        defaultValue={playlist.duration}
                        className="form-control rounded-custom"
                        required
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {pdf_access ? (
                    <div className="form-group pt-4">
                      <label htmlFor="duration">
                        PDF Page duration (in seconds)
                      </label>
                      {is_playlist_loaded ? (
                        <input
                          type="number"
                          name="pdf_duration"
                          id="pdf_duration"
                          defaultValue={playlist.pdf_duration}
                          className="form-control rounded-custom"
                          required
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group pt-4">
                    <label htmlFor="playlist_name">Playlist Name</label>
                    {is_playlist_loaded ? (
                      <input
                        type="playlist_name"
                        name="playlist_name"
                        id="playlist_name"
                        defaultValue={playlist.name}
                        className="form-control rounded-custom"
                        required
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-5 ">
                    <small className="text-muted">
                      All changes of playlist settings, Active clips, and
                      banners will be saved, please check before updating the
                      playlist.
                    </small>
                    <button
                      type="submit"
                      className="btn btn-primary rounded-custom px-3 w-100"
                    >
                      Update Playlist {loader}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card mt-4">
                <div className="card-header text-center bg-transparent p-3">
                  <h6 className="pb-0 mb-0">Active Clips / Banners</h6>
                </div>
                <div className="card-body py-4 wilyer_playlist_active_content">
                  {playlist_content.map((data, index) => (
                    <div className="card mt-2 border" key={index}>
                      <div className="card-body row">
                        <div className="col-auto">
                          {data.content !== null ? (
                            data.content.type === "image" ? (
                              <img
                                src={data.content.thumbnail}
                                className="rounded-custom mt-1 shadow-sm"
                                height="60"
                                width="60"
                                alt=""
                              />
                            ) : data.content.type === "video" ? (
                              <img
                                src="/assets/img/banners/s1.png"
                                className="rounded-custom mt-1 shadow-sm"
                                height="60"
                                width="60"
                                alt=""
                              />
                            ) : data.content.type === "pdf" ? (
                              <img
                                src="/assets/img/banners/s3.png"
                                className="rounded-custom mt-1 shadow-sm"
                                height="60"
                                width="60"
                                alt=""
                              />
                            ) : (
                              ""
                            )
                          ) : (
                            <img
                              src="/assets/img/banners/s2.png"
                              className="rounded-custom mt-1 shadow-sm"
                              height="60"
                              width="60"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="col-auto">
                          <strong>
                            {data.content !== null
                              ? data.content.name.substring(0, 20)
                              : data.web.name.substring(0, 20)}
                          </strong>
                          <small className="text-muted">
                            <br />
                            {data.content !== null ? (
                              data.content.type === "image" ? (
                                <span>
                                  <Image /> Image
                                </span>
                              ) : data.content.type === "video" ? (
                                <span>
                                  <Video /> Video
                                </span>
                              ) : data.content.type === "pdf" ? (
                                <span>
                                  <Clipboard /> PDF
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              <span>
                                <Link /> URL{" "}
                                <span
                                  className="d-inline h4"
                                  style={{ lineHeight: 0 }}
                                >
                                  .
                                </span>{" "}
                                <a
                                  href={data.web.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-decoration-none"
                                >
                                  {data.web.url}
                                </a>
                              </span>
                            )}
                          </small>
                          <br />
                          <button
                            type="button"
                            className="btn btn-sm btn-primary rounded-custom px-2 mr-1"
                            data-toggle="modal"
                            data-target="#view_file_modal"
                            onClick={() =>
                              data.content !== null
                                ? this.setState({
                                    preview_file_name: data.content.name,
                                    preview_file_type: data.content.type,
                                    preview_file_path: data.content.url,
                                  })
                                : this.setState({
                                    preview_file_name: data.web.name,
                                    preview_file_type: "url",
                                    preview_file_path: data.web.url,
                                  })
                            }
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-warning rounded-custom px-2"
                            onClick={() =>
                              this.remove(
                                data.content !== null
                                  ? data.content.id
                                  : data.web.id
                              )
                            }
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card mt-4">
                <div className="card-header text-center bg-transparent p-3">
                  <h6 className="pb-0 mb-0">All Clips / Banners</h6>
                </div>
                <div className="card-body py-4 wilyer_playlist_active_content">
                  <small className="text-muted">
                    <strong>Note:</strong> Only those clips/banners are here
                    those are not in <strong>Active Clips & banners</strong>
                  </small>
                  <br />
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="form-control rounded-custom mt-2"
                    placeholder="Search name"
                    onChange={this.search}
                  />
                  <button
                    className={
                      filter === "all"
                        ? "btn btn-primary rounded-custom my-2 mr-1 shadow-sm"
                        : "btn btn-white border rounded-custom my-2 mr-1 shadow-sm"
                    }
                    type="button"
                    onClick={() => this.filter("all")}
                  >
                    All
                  </button>
                  <button
                    className={
                      filter === "media"
                        ? "btn btn-primary rounded-custom my-2 mr-1 shadow-sm"
                        : "btn btn-white border rounded-custom my-2 mr-1 shadow-sm"
                    }
                    type="button"
                    onClick={() => this.filter("media")}
                  >
                    Clips & Banners
                  </button>
                  {pdf_access ? (
                    <button
                      className={
                        filter === "pdf"
                          ? "btn btn-primary rounded-custom my-2 mr-1 shadow-sm"
                          : "btn btn-white border rounded-custom my-2 mr-1 shadow-sm"
                      }
                      type="button"
                      onClick={() => this.filter("pdf")}
                    >
                      PDFs
                    </button>
                  ) : (
                    ""
                  )}
                  {pdf_access ? (
                    <button
                      className={
                        filter === "url"
                          ? "btn btn-primary rounded-custom my-2 mr-1 shadow-sm"
                          : "btn btn-white border rounded-custom my-2 mr-1 shadow-sm"
                      }
                      type="button"
                      onClick={() => this.filter("url")}
                    >
                      URLs
                    </button>
                  ) : (
                    ""
                  )}

                  {all_content.map((data, index) => (
                    <div className="card mt-2 border" key={index}>
                      <div className="card-body row">
                        <div className="col-auto">
                          {data.type === "image" ? (
                            <img
                              src={data.thumbnail}
                              className="rounded-custom mt-1 shadow-sm"
                              height="60"
                              width="60"
                              alt=""
                            />
                          ) : data.type === "video" ? (
                            <img
                              src="/assets/img/banners/s1.png"
                              className="rounded-custom mt-1 shadow-sm"
                              height="60"
                              width="60"
                              alt=""
                            />
                          ) : data.type === "pdf" ? (
                            <img
                              src="/assets/img/banners/s3.png"
                              className="rounded-custom mt-1 shadow-sm"
                              height="60"
                              width="60"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/assets/img/banners/s2.png"
                              className="rounded-custom mt-1 shadow-sm"
                              height="60"
                              width="60"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="col-auto">
                          <strong>{data.name.substring(0, 20)}</strong>
                          <small className="text-muted">
                            <br />
                            {data.type === "image" ? (
                              <span>
                                <Image /> Image
                              </span>
                            ) : data.type === "video" ? (
                              <span>
                                <Video /> Video
                              </span>
                            ) : data.type === "pdf" ? (
                              <span>
                                <Clipboard /> PDF
                              </span>
                            ) : (
                              <span>
                                <Link /> URL{" "}
                                <span
                                  className="d-inline h4"
                                  style={{ lineHeight: 0 }}
                                >
                                  .
                                </span>{" "}
                                <a
                                  href={data.url}
                                  rel="noreferrer"
                                  className="text-decoration-none"
                                >
                                  {data.url}
                                </a>
                              </span>
                            )}
                          </small>
                          <br />
                          <button
                            type="button"
                            className="btn btn-sm btn-primary rounded-custom px-2 mr-1"
                            data-toggle="modal"
                            data-target="#view_file_modal"
                            onClick={() =>
                              this.setState({
                                preview_file_name: data.name,
                                preview_file_type: data.type,
                                preview_file_path: data.url,
                              })
                            }
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-success rounded-custom px-2"
                            onClick={() => this.add(data.id)}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* View playlist Items */}
        <Modal
          key="view_file_modal"
          id="view_file_modal"
          title={preview_file_name}
          className="modal-xl"
        >
          <div className="modal-body text-center p-4 mb-3">
            <div className="mt-4">
              {preview_file_type === "image" ? (
                <img
                  src={preview_file_path}
                  alt={preview_file_name}
                  key={preview_file_type}
                  className="playlist_preview_image"
                />
              ) : (
                ""
              )}
              {preview_file_type === "video" ? (
                <video
                  controls
                  className="playlist_preview_image"
                  key={preview_file_path}
                >
                  <source src={preview_file_path} />
                </video>
              ) : (
                ""
              )}
              {preview_file_type === "pdf" ? (
                <iframe
                  className="iframe"
                  style={{ height: "600px", width: "100%" }}
                  title={preview_file_name}
                  key={preview_file_path}
                  src={preview_file_path}
                />
              ) : !["pdf", "image", "video"].includes(preview_file_type) ? (
                <iframe
                  className="iframe"
                  title={preview_file_name}
                  style={{ height: "600px", width: "100%" }}
                  key={preview_file_path}
                  src={preview_file_path}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}
