import React, { Fragment, PureComponent } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import Axios from "axios";
import { auth, config, server, logo, mode } from "../routes/env";

export default class Sidebar extends PureComponent {
  state = {
    hours: new Date().getHours(),
    current_theme: localStorage.getItem("theme-mode"),
    active_tab: window.location.href.split("/")[3],
    whiteLabelStatus: false,
    whiteLabelFile: "",
    user_type: "",
  };

  setItem = (key, value) => {
    localStorage.setItem(key, value);
  };
  getItem = (key) => {
    return localStorage.getItem(key);
  };

  componentDidMount = () => {
    JSON.stringify(this.state.current_theme) === "null"
      ? this.adaptive(this.state.hours)
      : this.state.current_theme === "dark"
      ? this.adaptive(19) // For dark mode
      : this.adaptive(8); // For light mode

    Axios.post(server + "/account/verify", {}, config).then((rsp) => {
      if (rsp.data.hasOwnProperty("is_user")) {
        this.setState({
          user_type: "user",
        });
      } else {
        this.setState({
          user_type: "enterprise",
        });
      }
    });

    Axios.get(server + "/account/whiteLabelStatus", config)
      .then((rsp) => {
        this.setState({
          whiteLabelStatus: rsp.data.status,
          whiteLabelFile: rsp.data.url,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  adaptive = (hours) => {
    if (hours >= 18 || hours <= 7) {
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/dark-theme.css");
      this.setState({
        current_theme: "light",
      });
    } else {
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/light-theme.css");
      this.setState({
        current_theme: "dark",
      });
    }
  };

  changeTheme = () => {
    if (this.getItem("theme-mode") === "dark") {
      this.setItem("theme-mode", "light");
      this.adaptive(8);
    } else {
      this.setItem("theme-mode", "dark");
      this.adaptive(19);
    }
  };

  set_active = (tab) => {
    this.setState({
      active_tab: tab,
    });
    document.getElementById("sidebarMenu").classList.remove("show");
  };

  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenDate");
    window.location.href = auth + "/logout";
  };

  render() {
    const {
      current_theme,
      active_tab,
      whiteLabelStatus,
      whiteLabelFile,
      user_type,
    } = this.state;
    return (
      <nav
        id="sidebarMenu"
        className="col-md-3 col-lg-2 d-md-block sidebar collapse pt-5"
      >
        <div className="text-center d-none d-md-block">
          <a className="navbar-brand" href="/">
            <img
              src={
                whiteLabelStatus
                  ? whiteLabelFile !== ""
                    ? whiteLabelFile
                    : "/assets/img/logo/signage-cloud-logo.png"
                  : "/assets/img/logo/" + logo
              }
              height="50"
              width="100"
              className="navbar-brand-img mx-auto"
              alt=""
            />
          </a>
        </div>
        <div className="position-sticky pt-5">
          <ul className="nav flex-column">
            <li className="nav-item pb-3 text-left ml-3">
              <button
                className={
                  current_theme === null || current_theme === "light"
                    ? "btn btn-white btn-sm rounded-custom pl-3 pr-3 mr-1"
                    : "btn btn-dark btn-sm rounded-custom pl-3 pr-3 mr-1"
                }
                onClick={this.changeTheme}
                id="theme-mode"
              >
                {current_theme === null || current_theme === "light" ? (
                  <span id="theme-name">Light Mode</span>
                ) : (
                  <span id="theme-name">Dark Mode</span>
                )}
              </button>
            </li>

            {user_type === "enterprise" ? (
              <li className="nav-item">
                <Link
                  to="/"
                  className={active_tab === "" ? "nav-link active" : "nav-link"}
                  aria-current="page"
                  onClick={() => this.set_active("")}
                >
                  <Icon.Home size={14} /> Dashboard
                </Link>
              </li>
            ) : (
              ""
            )}

            <li className="nav-item">
              <Link
                to={user_type === "enterprise" ? "/screens" : ""}
                className={
                  active_tab === (user_type === "enterprise" ? "screens" : "")
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => this.set_active("screens")}
              >
                <Icon.Airplay size={14} /> Screens
              </Link>
            </li>

            {user_type === "enterprise" ? (
              <li className="nav-item">
                <Link
                  to="/users"
                  className={
                    active_tab === "users" ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  onClick={() => this.set_active("users")}
                >
                  <Icon.Users size={14} /> Users
                </Link>
              </li>
            ) : (
              ""
            )}

            <li className="nav-item">
              <Link
                to="/playlist"
                className={
                  active_tab === "playlist" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("playlist")}
              >
                <Icon.Film size={14} /> Playlists
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/library"
                className={
                  active_tab === "library" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("library")}
              >
                <Icon.Grid size={14} /> Library
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/support"
                className={
                  active_tab === "support" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("support")}
              >
                <Icon.Info size={14} /> Support
              </Link>
            </li>
          </ul>

          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Documentation</span>
            <span className="link-secondary">
              <Icon.Book size={14} />
            </span>
          </h6>
          <ul className="nav flex-column mb-2">
            <li className="nav-item">
              <Link
                to="/faqs"
                className={
                  active_tab === "faqs" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("faqs")}
              >
                <Icon.AlertOctagon size={14} /> FAQs
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/help"
                className={
                  active_tab === "help" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("help")}
              >
                <Icon.HelpCircle size={14} /> Help?
              </Link>
            </li>
            {mode === "wilyer" ? (
              <li className="nav-item">
                <Link
                  to="/contact"
                  className={
                    active_tab === "contact" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.set_active("contact")}
                >
                  <Icon.Mail size={14} /> Contact Us
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Account</span>
            <span className="link-secondary">
              <Icon.User size={14} />
            </span>
          </h6>
          <ul className="nav flex-column mb-2">
            <li className="nav-item">
              <Link
                to="/settings"
                className={
                  active_tab === "settings" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("settings")}
              >
                <Icon.Settings size={14} /> Settings
              </Link>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className={
                  active_tab === "logout"
                    ? "nav-link text-danger active"
                    : "nav-link text-danger"
                }
                onClick={this.logout}
              >
                <Icon.Power size={14} color="#dc3545" /> Logout
              </a>
            </li>
          </ul>
          {!whiteLabelStatus ? (
            <Fragment>
              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Releases</span>
                <a
                  className="link-secondary"
                  href="javascipt:void(0)"
                  aria-label="Add a new report"
                >
                  <Icon.Database size={14} />
                </a>
              </h6>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <Link
                    to="/updates"
                    className={
                      active_tab === "updates" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => this.set_active("updates")}
                  >
                    <Icon.Package size={14} /> Updates
                  </Link>
                </li>
              </ul>
            </Fragment>
          ) : (
            ""
          )}
        </div>
      </nav>
    );
  }
}
