import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Notification } from "react-notification";
import Axios from "axios";
import { Settings, Trash2 } from "react-feather";
import Modal from "../../components/modal";
import { server, config } from "../../routes/env";
import hideModal from "../../components/HideModal";
import resetForm from "../../components/ResetForm";
import Spinner from "../../components/Spinner";

export default class Playlist extends PureComponent {
  state = {
    url: "/signage/playlist/read",
    playlists: [],
    next: null,
    total_playlists: 0,
    current_playlist: "",
    show_more_button: "Show more",
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    // add playlist
    btn_spinner: <Spinner />,
    message: "",
    add_playlist_loader: "",
    notification: false,
    allocated_screens: [],
  };

  componentDidMount = () => {
    document.title = "Playlists - Signage Cloud";
    this.read_playlists(this.state.url);
  };

  read_playlists = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        total_playlists: rsp.data.count,
        playlists: search
          ? rsp.data.results
          : this.state.playlists.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^\/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  search = (event) => {
    this.read_playlists(this.state.url + "?search=" + event.target.value, true);
  };

  show_more = (url) => {
    this.read_playlists(url);
  };

  add_playlist = (event) => {
    event.preventDefault();
    this.setState({
      add_playlist_loader: this.state.btn_spinner,
    });

    const params = {
      name: event.target.playlist_name.value,
    };

    Axios.post(server + "/signage/playlist/create", params, config)
      .then((rsp) => {
        hideModal(0);
        resetForm();
        // this.read_playlists(this.state.url, true);
        this.setState({
          message: rsp.data.detail,
          add_playlist_loader: "",
          notification: true,
        });

        this.props.history.push("/playlist_settings/" + rsp.data.playlist);
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
          add_playlist_loader: "",
        });
      });
  };

  delete_playlist = () => {
    Axios.delete(
      server + "/signage/playlist/delete/" + this.state.current_playlist + "/",
      config
    )
      .then((rsp) => {
        hideModal(1);
        this.setState({
          message: rsp.data.detail,
          notification: true,
          playlists: this.state.playlists.filter(
            (data) => data.id !== this.state.current_playlist
          ),
        });
      })
      .catch((error) => {
        this.setState({
          allocated_screens: error.response.data.hasOwnProperty("screens")
            ? error.response.data.screens
            : [],
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  render() {
    const {
      playlists,
      show_more_button,
      total_playlists,
      next,
      add_playlist_loader,
      message,
      notification,
      allocated_screens,
    } = this.state;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={3000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4 shadow-sm">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Playlists</h3>
            </div>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between gy-3">
              <div className="col-md-6">
                <button
                  className="btn btn-primary  rounded-custom mr-1 mb-1 px-3"
                  data-toggle="modal"
                  data-target="#add_playlist_modal"
                >
                  Add Playlist
                </button>
                <Link
                  to="/playlists_to_screens"
                  className="btn btn-primary  rounded-custom mb-1 px-3"
                >
                  Assign Playlists To Screens
                </Link>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-muted rounded-custom pl-3"
                  placeholder="search"
                  onChange={this.search}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-body p-0 border-0">
            <div className="table-responsive">
              <table className="table table-striped table-hover mb-0">
                <thead>
                  <tr className="text-center text-muted">
                    <th>#</th>
                    <th>Name</th>
                    <th>Total Content</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {playlists.map((data, index) => (
                    <tr className="text-center text-muted" key={index}>
                      <td className="td-pt-3">#{index + 1}</td>
                      <td className="td-pt-3">{data.name}</td>
                      <td className="td-pt-3">{data.files} files</td>
                      <td>
                        <Link
                          to={"/playlist_settings/" + data.id}
                          className="btn btn-primary  btn-sm mr-1"
                        >
                          <Settings />
                        </Link>
                        <button
                          className="btn btn-danger btn-sm border-0"
                          data-toggle="modal"
                          data-target="#delete_playlist_modal"
                          onClick={() =>
                            this.setState({ current_playlist: data.id })
                          }
                        >
                          <Trash2 />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {playlists.length} of {total_playlists}
                </h6>
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white  rounded-custom border px-3"
                  onClick={
                    next == null ? null : () => this.read_playlists(next)
                  }
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Add playlist modal */}
        <Modal
          key="add_playlist_modal"
          id="add_playlist_modal"
          title="Add Playlist"
        >
          <form action="" onSubmit={this.add_playlist}>
            <div className="modal-body p-4">
              <div className="form-group">
                <label htmlFor="playlist_name">Playlist name</label>
                <input
                  type="text"
                  name="playlist_name"
                  id="playlist_name"
                  className="form-control rounded-custom"
                  required
                />
              </div>
            </div>
            <div className="modal-footer border-0 pb-4">
              <button
                type="submit"
                className="btn btn-primary  rounded-custom pl-3 pr-3"
              >
                Add Playlist {add_playlist_loader}
              </button>

              <button
                type="reset"
                className="btn btn-danger  lift rounded-custom pl-3 pr-3"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </Modal>

        {/* Delete playlist */}
        <Modal
          key="delete_playlist_modal"
          id="delete_playlist_modal"
          title="Delete Playlist"
        >
          <div className="modal-body text-center p-4 mb-3">
            <h5>Are you sure you want to delete playlist?</h5>
            <small className="text-muted">
              {allocated_screens.length > 0
                ? "This playlist allocated to following playlists."
                : "Make sure playlist is not allocated to screen."}
              {allocated_screens.map((data, index) => (
                <span key={index} className="text-danger">
                  <br />
                  Screen Name - {data.name}
                </span>
              ))}
            </small>
            <div className="mt-4">
              <button
                className="btn btn-success  rounded-custom rounded-lg "
                data-dismiss="modal"
              >
                No
              </button>
              <button
                className="btn btn-danger  rounded-custom ml-2"
                onClick={this.delete_playlist}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}
