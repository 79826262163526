import React, { PureComponent } from "react";
import axios from "axios";
import { server, config } from "../../routes/env";
import { Trash2 } from "react-feather";
import Spinner from "../../components/Spinner";
import Axios from "axios";

export default class SharedAccess extends PureComponent {
  state = {
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    screen_id: this.props.screen_id,
    show_more_button: "Show more",
    sharedAccess: [],
    totalAccess: 0,
    next: null,
    url: "/signage/screen/access/read/" + this.props.screen_id,
  };

  componentDidMount = () => {
    this.readAccess(this.state.url);
  };

  readAccess = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    axios.get(server + url, config).then((rsp) => {
      this.setState({
        totalAccess: rsp.data.count,
        sharedAccess: search
          ? rsp.data.results
          : this.state.sharedAccess.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  revokeAccess = async (id) => {
    this.setState({
      sharedAccess: this.state.sharedAccess.filter((data) => data.id !== id),
      totalAccess: this.state.totalAccess - 1,
    });

    Axios.put(server + "/signage/screen/access/revoke/" + id, {}, config)
      .then((rsp) => {})
      .catch((err) => {});
  };

  render() {
    const { show_more_button, sharedAccess, totalAccess, next } = this.state;

    return (
      <div className="card mt-4">
        <div className="card-body">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="card-title h5 text-muted d-inline-block">
              Shared Access
            </div>
          </div>
          <div className="card-body">
            <div className="card-body p-0 border-0">
              <div className="table-responsive">
                <table className="table table-striped table-hover mb-0">
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>Name</th>
                      <th>email</th>
                      <th>Added On</th>
                      <th>Revoke Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sharedAccess.map((data, index) => (
                      <tr className="text-center text-muted" key={index}>
                        <td className="td-pt-3">#{index + 1}</td>
                        <td className="td-pt-3">{data.username}</td>
                        <td className="td-pt-3">{data.email}</td>
                        <td className="td-pt-3">
                          {new Date(data.created).toLocaleDateString()}
                        </td>

                        <td>
                          <button
                            className="btn btn-sm btn-danger rounded-custom"
                            onClick={() => this.revokeAccess(data.id)}
                          >
                            <Trash2 />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer bg-transparent border-0 p-3">
              <div className="row">
                <div className="col-6 text-muted">
                  <h6 className="h6 pt-2 small">
                    Showing {sharedAccess.length} of {totalAccess}
                  </h6>
                </div>

                <div className="col-6 text-right">
                  <button
                    className="btn btn-white btn-sm rounded-custom border px-3"
                    onClick={next == null ? null : () => this.readAccess(next)}
                  >
                    {show_more_button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
