import React, { PureComponent } from "react";
import { Settings } from "react-feather";
import Modal from "../../components/modal";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Axios from "axios";
import { server, config } from "../../routes/env";
import { Notification } from "react-notification";
import resetForm from "../../components/ResetForm";
import hideModal from "../../components/HideModal";

export default class Screen extends PureComponent {
  state = {
    url: "/enterprise/users",
    users: [],
    next: null,
    total_users: 0,
    show_more_button: "Show more",
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    add_user_loader: "",
    notification: false,
    message: "",
  };

  componentDidMount = () => {
    document.title = "Users - Signage Cloud Enterprise";
    this.read_users(this.state.url);
  };

  read_users = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        total_users: rsp.data.count,
        users: search
          ? rsp.data.results
          : this.state.users.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^\/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  search = (event) => {
    this.read_users(this.state.url + "?search=" + event.target.value, true);
  };

  show_more = (url) => {
    this.read_users(url);
  };

  add_user = (event) => {
    event.preventDefault();
    this.setState({
      add_user_loader: <Spinner />,
    });

    const params = {
      username: event.target.username.value,
      email: event.target.email.value,
      password: event.target.password.value,
    };

    Axios.post(server + "/enterprise/create_user", params, config)
      .then((rsp) => {
        resetForm(0);
        hideModal(0);
        this.setState({
          add_user_loader: "",
          notification: true,
          message: rsp.data.detail,
        });

        this.props.history.push('/user/'+rsp.data.user)

        // this.read_users(this.state.url, true);
      })
      .catch((error) => {
        this.setState({
          add_user_loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  render() {
    const {
      users,
      show_more_button,
      total_users,
      next,
      add_user_loader,
      notification,
      message,
    } = this.state;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={4000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Users</h3>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between gy-3">
              <div className="col-md-6">
                <div className="btn-toolbar mb-0 mb-md-0">
                  <button
                    className="btn btn-primary btn-sm rounded-custom pl-3 pr-3 mr-1"
                    data-toggle="modal"
                    data-target="#add_user_modal"
                  >
                    Add New User
                  </button>
                  <button
                    className="btn btn-primary btn-sm rounded-custom pl-3 pr-3"
                    data-toggle="modal"
                    data-target="#download_app_modal"
                  >
                    Download App
                  </button>
                </div>
              </div>
              <div className="col-md-4 text-right">
                <input
                  type="text"
                  className="form-control text-muted rounded-custom pl-3"
                  placeholder="Search by name"
                  onChange={this.search}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-body p-0 border-0">
            <div className="table-responsive">
              <table className="table table-striped table-hover mb-0">
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>joined</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((data, index) => (
                    <tr key={index}>
                      <td className="td-pt-3 text-center">#{index + 1}</td>
                      <td className="td-pt-3 text-center">{data.full_name}</td>
                      <td className="td-pt-3 text-center">
                        <a
                          target="_blank"
                          rel="noopner noreferrer"
                          className="text-decoration-none"
                          href={"mailto:" + data.email}
                        >
                          {data.email}
                        </a>
                      </td>
                      <td className="td-pt-3 text-center">
                        {data.is_active ? (
                          <span className="badge bg-success">Active</span>
                        ) : (
                          <span className="badge bg-danger">Not Active</span>
                        )}
                      </td>
                      <td className="td-pt-3 text-center">
                        {new Date(data.date_joined).toDateString()}
                      </td>
                      <td className="text-center">
                        <Link
                          to={"/user/" + data.id}
                          className="btn btn-sm btn-primary"
                        >
                          <Settings />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {users.length} of {total_users}
                </h6>
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm rounded-custom border px-3"
                  onClick={next == null ? null : () => this.read_users(next)}
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Add New User modal */}
        <Modal key="add_user_modal" id="add_user_modal" title="Add New User">
          <div className="modal-body pt-0 px-4 mb-3">
            <form onSubmit={this.add_user}>
              <div className="form-group">
                <label htmlFor="username">Full Name</label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-gorup float-right mt-4">
                <button className="btn btn-primary">
                  Add User {add_user_loader}
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* Download app modal */}
        <Modal
          key="download_app_modal"
          id="download_app_modal"
          title="Download Latest App"
        >
          <div className="modal-body pt-0 mb-3">
            <div className="card border mb-3">
              <div className="card-body pb-0">
                <h6>Requirement for app v2.0</h6>
                <ul>
                  <li>USB Pen Drive or SD Card to install APK</li>
                  <li>Android Device with v4.3 and above</li>
                  <li>Wi-Fi or Internet to Pair Screen with Account</li>
                </ul>
              </div>
            </div>
            <button className="btn btn-white border rounded-xl  pl-3 pr-3">
              <div className="mt-1"></div>
              <img
                src="/assets/img/icons/google_play.svg"
                height="25"
                width="25"
                className="mb-0"
                alt="wilyer app download icon"
              />
              <span>Download Now</span>
            </button>
          </div>
        </Modal>
      </main>
    );
  }
}
