import React from "react";
import { logo } from "../routes/env";

const topbar = () => {
  return (
    <nav className="navbar navbar-light sticky-top flex-md-nowrap shadow-sm d-sm-block d-xs-block d-md-none">
      <div className="container-fluid">
        <a href="/" className="navbar-brand">
          <img
            src={"/assets/img/logo/" + logo}
            height="40"
            width="80"
            alt="wilyer signage"
            className="d-inline-block align-top"
            loading="lazy"
          />
        </a>
        <button
          className="navbar-toggler position-right d-md-none collapsed border-0"
          type="button"
          data-toggle="collapse"
          data-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default topbar;
