import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import { Notification } from "react-notification";
import Axios from "axios";
import { Settings, Trash2 } from "react-feather";
import Modal from "../../components/modal";
import { server, config } from "../../routes/env";
import hideModal from "../../components/HideModal";
import resetForm from "../../components/ResetForm";
import Spinner from "../../components/Spinner";

function getLicenseExpiryClass(date) {
  let now = new Date();
  let d = new Date(date);
  let diff = d - now;
  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (days < 30 && days >= 10) {
    return {
      class: "",
      bgClass: "bg-warning",
      days,
    };
  } else if (days <= 10) {
    return {
      class: "text-dark",
      bgClass: "bg-danger",
      days,
    };
  }
  return {
    class: "text-success",
    bgClass: "",
    days,
  };
}

export default class Screen extends PureComponent {
  state = {
    url: "/signage/screen/read",
    screens: [],
    next: null,
    total_screens: 0,
    current_screen: "",
    show_more_button: "Show more",
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    // add screen
    btn_spinner: <Spinner />,
    message: "",
    add_screen_loader: "",
    notification: false,

    // export screens status
    exportLaoder: "",
    exportData: [],
  };

  componentDidMount = () => {
    document.title = "Screens - Signage Cloud";
    this.read_screens(this.state.url);
  };

  read_screens = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        total_screens: rsp.data.count,
        screens: search
          ? rsp.data.results
          : this.state.screens.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^\/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  readScreensStatus = async () => {
    this.setState({
      exportLaoder: this.state.spinner,
    });

    await Axios.get(server + "/signage/screen/status/read", config)
      .then((rsp) => {
        this.setState({
          exportData: rsp.data,
        });

        var csv = this.state.exportData.map((data) => {
          return {
            Name: data.name,
            Status: data.status ? "Online" : "Offline",
            "Last Response": new Date(data.last_response),
          };
        });

        // add headers
        csv.unshift({
          Name: "Name",
          Status: "Status",
          "Last Response": "Last Response",
        });

        csv = csv
          .map((data) => {
            return Object.values(data).join(",");
          })
          .join("\n");

        // download csv file
        const csvFile = new Blob([csv], { type: "text/csv" });
        const downloadLink = document.createElement("a");
        downloadLink.download = "uptime_report.csv";
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.click();
      })
      .catch((err) => {});

    this.setState({
      exportLaoder: "",
    });
  };

  search = (event) => {
    this.read_screens(this.state.url + "?search=" + event.target.value, true);
  };

  show_more = (url) => {
    this.read_screens(url);
  };

  add_screen = (event) => {
    event.preventDefault();
    this.setState({
      add_screen_loader: this.state.btn_spinner,
    });

    const params = {
      otp: parseInt(event.target.pairing_code.value),
      name: event.target.screen_name.value,
    };

    Axios.post(server + "/signage/screen/create", params, config)
      .then((rsp) => {
        hideModal(0);
        resetForm();
        this.setState({
          message: rsp.data.detail,
          add_screen_loader: "",
          notification: true,
        });
        this.read_screens(this.state.url, true);
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
          add_screen_loader: "",
        });
      });
  };

  delete_screen = () => {
    document.getElementById(this.state.screen_dom_id).classList.add("d-none");
    Axios.delete(
      server + "/signage/screen/delete/" + this.state.current_screen + "/",
      config
    )
      .then((rsp) => {
        hideModal(2);
        this.setState({
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  render() {
    const {
      screens,
      show_more_button,
      total_screens,
      next,
      add_screen_loader,
      message,
      notification,
    } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={3000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Screens</h3>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between gy-3">
              <div className="col-md-6">
                <div className="btn-toolbar mb-0 mb-md-0">
                  <button
                    className="btn btn-primary btn-sm rounded-custom pl-3 pr-3 mr-2"
                    data-toggle="modal"
                    data-target="#add_screen_modal"
                  >
                    New Screen
                  </button>
                  <button
                    className="btn btn-primary btn-sm rounded-custom pl-3 pr-3"
                    onClick={this.readScreensStatus}
                  >
                    Export Uptime Report {this.state.exportLaoder}
                  </button>
                </div>
              </div>
              <div className="col-md-4 text-right">
                <input
                  type="text"
                  className="form-control text-muted rounded-custom pl-3"
                  placeholder="Search by screen name"
                  onChange={this.search}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-body p-0 border-0">
            <div className="table-responsive">
              <table className="table table-striped table-hover mb-0">
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>Name</th>
                    <th>Playing</th>
                    <th>Status</th>
                    <th>Last Response</th>
                    <th>Expiry</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {screens.map((data, index) => (
                    <tr
                      className={
                        "text-center text-dark " +
                        getLicenseExpiryClass(data.expiry).bgClass
                      }
                      key={index}
                      id={"screen_" + index}
                    >
                      <td className="td-pt-3 text-dark">#{index + 1}</td>
                      <td className="td-pt-3 text-dark">{data.name}</td>
                      <td className="td-pt-3 text-dark">{data.content}</td>
                      <td className="td-pt-3 text-dark">
                        {data.settings.status ? (
                          <>
                            <span className="badge bg-success">Online</span>
                          </>
                        ) : (
                          <>
                            <span className="badge bg-danger">Offline</span>
                          </>
                        )}
                      </td>
                      <td className="td-pt-3 text-dark">
                        {new Date(
                          data.settings?.last_response
                        ).toLocaleString()}
                      </td>
                      <td className="td-pt-3 text-dark">
                        {getLicenseExpiryClass(data.expiry).days <= 30 && (
                          <Fragment>
                            Expiring soon <br />
                          </Fragment>
                        )}
                        {new Date(data.expiry).toDateString()}
                      </td>

                      <td>
                        <Link
                          to={"/screen/" + data.id}
                          className="btn btn-sm btn-primary mr-1"
                          title="Setting"
                        >
                          <Settings />
                        </Link>
                        <button
                          className="btn btn-sm btn-danger rounded-custom"
                          data-toggle="modal"
                          data-target="#delete_screen_modal"
                          title="Delete"
                          onClick={() =>
                            this.setState({
                              current_screen: data.id,
                              screen_dom_id: "screen_" + index,
                            })
                          }
                        >
                          <Trash2 />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {screens.length} of {total_screens}
                </h6>
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm rounded-custom border px-3"
                  onClick={next == null ? null : () => this.read_screens(next)}
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Add screen modal */}
        <Modal key="add_screen_modal" id="add_screen_modal" title="Add Screen">
          <form action="" onSubmit={this.add_screen}>
            <div className="modal-body p-4">
              <div className="form-group">
                <label htmlFor="pairing_code">Pairing Code</label>
                <input
                  type="number"
                  name="pairing_code"
                  id="pairing_code"
                  className="form-control rounded-custom"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="screen_name">Screen Name</label>
                <input
                  type="text"
                  name="screen_name"
                  id="screen_name"
                  className="form-control rounded-custom"
                  required
                />
              </div>
              <small>
                <Link
                  to="/help"
                  onClick={() => hideModal(0)}
                  className="text-decoration-none"
                >
                  Need help?
                </Link>
              </small>
            </div>
            <div className="modal-footer border-0 pb-4">
              <button
                type="submit"
                className="btn btn-primary btn-sm rounded-custom pl-3 pr-3"
              >
                Add Screen {add_screen_loader}
              </button>

              <button
                type="reset"
                className="btn btn-danger btn-sm lift rounded-custom pl-3 pr-3"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </Modal>

        {/* Download app modal */}
        <Modal
          key="download_app_modal"
          id="download_app_modal"
          title="Download Latest App"
        >
          <div className="modal-body pt-0 mb-3">
            <div className="card border mb-3 shadow-sm">
              <div className="card-body pb-0">
                <h6>Requirement for app v2.0</h6>
                <ul>
                  <li>USB Pen Drive or SD Card to install APK</li>
                  <li>Android Device with v4.3 and above</li>
                  <li>Wi-Fi or Internet to Pair Screen with Account</li>
                </ul>
              </div>
            </div>
            <button className="btn btn-white border rounded-xl shadow-sm  pl-3 pr-3">
              <div className="mt-1"></div>
              <img
                src="/assets/img/icons/google_play.svg"
                height="25"
                width="25"
                className="mb-0"
                alt="wilyer app download icon"
              />
              <span>Download Now</span>
            </button>
          </div>
        </Modal>

        <Modal
          key="delete_screen_modal"
          id="delete_screen_modal"
          title="Delete Screen"
        >
          <div className="modal-body text-center p-4 mb-3">
            <h5>Are you sure you want to delete screen?</h5>
            <div className="mt-4">
              <button
                className="btn btn-primary btn-sm rounded-custom mr-2"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                className="btn btn-danger btn-sm rounded-custom"
                onClick={this.delete_screen}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}
