import React, { PureComponent } from "react";
import { Notification } from "react-notification";
import Axios from "axios";
import Spinner from "../../components/Spinner";
import { config, server } from "../../routes/env";
import resetForm from "../../components/ResetForm";

export default class Settings extends PureComponent {
  state = {
    loader: "",
    message: "",
    notification: false,
  };

  componentDidMount = () => {
    document.title = "User Settings - Signage Cloud";
  };

  update_password = (event) => {
    event.preventDefault();
    var old_password = event.target.old_password.value,
      new_password =
        event.target.new_password.value === event.target.cnf_password.value;
    if (new_password) {
      this.setState({
        loader: <Spinner />,
      });
      const params = {
        old_password,
        new_password: event.target.new_password.value,
      };

      Axios.post(server + "/account/update_password", params, config)
        .then((rsp) => {
          resetForm();
          this.setState({
            message: rsp.data.detail,
            loader: "",
            notification: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            message: error.response.data.hasOwnProperty("detail")
              ? error.response.data.detail
              : "Server error occured! contact to support team.",
            notification: true,
            loader: "",
          });
        });
    } else {
      this.setState({
        message: "Passwords didn't matched.",
        notification: true,
      });
    }
  };

  render() {
    const { loader, message, notification } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={3000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Account Settings</h3>
          </div>
        </div>
        <div className="row justify-content-start my-4">
          <div className="col-md-6">
            <div className="card p-3">
              <div className="card-header bg-transparent">
                <h6 className="card-title">Change Password</h6>
              </div>
              <form onSubmit={this.update_password}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="old_password">Old Password</label>
                    <input
                      type="password"
                      name="old_password"
                      id="old_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="new_password">New Password</label>
                    <input
                      type="password"
                      name="new_password"
                      id="new_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="cnf_password">Confirm Password</label>
                    <input
                      type="password"
                      name="cnf_password"
                      id="cnf_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group text-right mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm rounded-custom px-3"
                    >
                      Update Password {loader}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
