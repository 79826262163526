import React, { PureComponent } from "react";
import { Facebook, Twitter, Instagram, Linkedin } from "react-feather";

export default class Contact extends PureComponent {
  componentDidMount = () => {
    document.title = "Contact Us - Signage cloud";
  };
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Contact Us</h3>
          </div>
        </div>
        <div className="row justify-content-start my-4">
          <div className="col-md-6">
            <div class="card p-3">
              <div class="text-center mt-4">
                <img src="https://wilyer.com/img/logo.png" alt="Wilyer Logo" />
                <span></span>
              </div>
              <div class="card-body">
                <div class="row align-items-center my-3 border-bottom">
                  <div class="col">
                    <h4 class="card-header-title"> Contact Details </h4>
                  </div>
                </div>
                <strong>Company Name:</strong>
                <span>&nbsp;&nbsp;Wilyer</span>
                <br />
                <strong>Service Name:</strong>
                <span>&nbsp;&nbsp;Wilyer Signage</span>
                <br />
                <strong>Contact Number:</strong>
                <span>
                  &nbsp;&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none"
                    href="https://api.whatsapp.com/send?phone=918800581956"
                  >
                    {" "}
                    +918800581956
                  </a>{" "}
                  ,{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none"
                    href="https://api.whatsapp.com/send?phone=918595119451"
                  >
                    {" "}
                    +918595119451
                  </a>
                </span>
                <br />
                <strong>Email: </strong>
                <span>
                  &nbsp;&nbsp;
                  <a
                    rel="noopener noreferrer"
                    href="mailto:wilyerofficial@gmail.com"
                    className="text-decoration-none"
                  >
                    {" "}
                    wilyerofficial@gmail.com
                  </a>
                </span>
                <br />
                <strong>GST IN: </strong>
                <span>&nbsp;&nbsp;06AADFW2368L1ZT</span>
                <br />
                <strong>Address:</strong>&nbsp;&nbsp;
                <span>
                  V-11, Mapsko Casabella, Sector 82, Gurugram 122004, Haryana
                </span>
              </div>
              <div className="card-footer text-center bg-transparent pt-3">
                <a
                  href="#"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-primary rounded-circle"
                >
                  <Facebook />
                </a>{" "}
                <a
                  href="#"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-danger rounded-circle"
                >
                  <Instagram />
                </a>{" "}
                <a
                  href="#"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-info rounded-circle"
                >
                  <Twitter />
                </a>{" "}
                <a
                  href="#"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-primary rounded-circle"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
