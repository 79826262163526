import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import screen from "../source/dashboard/screen";
import ScreenSettings from "../source/dashboard/screen_settings";
import playlist from "../source/dashboard/playlist";
import PlaylistSettings from "../source/dashboard/playlist_settings";
import library from "../source/dashboard/library";
import Playlists_To_Screens from "../source/dashboard/playlists_to_screens";
// import Schedule from "../source/dashboard/schedule";
// import Transactions from "../source/dashboard/transaction";
import Support from "../source/dashboard/support";
// Documentation
import Faqs from "../source/dashboard/documentation/faqs";
import Help from "../source/dashboard/documentation/help";
import Contact from "../source/dashboard/documentation/contact";
// Settings
import Settings from "../source/dashboard/settings";
// Updates
import Updates from "../source/dashboard/documentation/updates";

// enterprise
import { config, server } from "../routes/env";
import Axios from "axios";

import users from "../source/dashboard/users";
import userSettings from "../source/dashboard/user_settings";
import enterpriseLibrary from "../source/dashboard/library-enter-p";
import Dashboard from "../source/dashboard/dashboard";

export default class Dashboard_Router extends PureComponent {
  state = {
    user_type: "",
  };
  componentDidMount = () => {
    Axios.post(server + "/account/verify", {}, config).then((rsp) => {
      if (rsp.data.hasOwnProperty("is_user")) {
        this.setState({
          user_type: "user",
        });
      } else {
        this.setState({
          user_type: "enterprise",
        });
      }
    });
  };
  render() {
    const { user_type } = this.state;
    return user_type === "user" ? (
      <Switch>
        {/* Updates */}
        <Route path="/updates" component={Updates} />

        {/* Settings */}
        <Route path="/settings" component={Settings} />

        {/* Documentation */}
        <Route path="/faqs" component={Faqs} />
        <Route path="/help" component={Help} />
        <Route path="/contact" component={Contact} />

        {/* Main */}
        {/* <Route path="/transactions" component={Transactions} /> */}
        <Route path="/support" component={Support} />
        {/* <Route path="/schedule" component={Schedule} /> */}
        <Route path="/library" component={library} />
        <Route path="/playlist" component={playlist} />
        <Route
          path="/playlist_settings/:playlist_id"
          component={PlaylistSettings}
        />
        <Route path="/playlists_to_screens" component={Playlists_To_Screens} />
        <Route path="/screen/:screen_id" component={ScreenSettings} />
        <Route
          path="/schedule_screen/:schedule_screen_id"
          component={ScreenSettings}
        />
        <Route path="/" component={screen} />
      </Switch>
    ) : user_type === "enterprise" ? (
      <Switch>
        {/* Updates */}
        <Route path="/updates" component={Updates} />

        {/* Settings */}
        <Route path="/settings" component={Settings} />

        {/* Documentation */}
        <Route path="/faqs" component={Faqs} />
        <Route path="/help" component={Help} />
        <Route path="/contact" component={Contact} />

        {/* Main */}
        {/* <Route path="/transactions" component={Transactions} /> */}
        <Route path="/support" component={Support} />
        {/* <Route path="/schedule" component={Schedule} /> */}
        <Route path="/library" component={enterpriseLibrary} />
        <Route path="/playlist" component={playlist} />
        <Route
          path="/playlist_settings/:playlist_id"
          component={PlaylistSettings}
        />
        <Route path="/playlists_to_screens" component={Playlists_To_Screens} />
        <Route path="/screen/:screen_id" component={ScreenSettings} />
        <Route
          path="/schedule_screen/:schedule_screen_id"
          component={ScreenSettings}
        />
        <Route path="/screens" component={screen} />
        <Route path="/user/:user_id" component={userSettings} />
        <Route path="/users" component={users} />
        <Route path="/" component={Dashboard} />
      </Switch>
    ) : (
      "Loading..."
    );
  }
}
