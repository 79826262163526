import React, { Fragment, PureComponent } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Axios from "axios";
import Topbar from "./components/header/topbar";
import Sidebar from "./components/header/sidebar";
import DashboardRouter from "./components/routes/dashboard";
import { auth, server } from "./components/routes/env";

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    if (
      Date.parse(new Date()) - Date.parse(localStorage.tokenDate) >
      20 * 60 * 60 * 1000
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenDate");
    }

    // From wilyer auth
    var token = window.location.href.split("/")[4];
    var check = window.location.href.split("/")[3];

    if (
      check !== "screen" &&
      check !== "schedule_screen" &&
      check !== "play" &&
      check !== "playlist_settings"
    ) {
      if (token !== undefined) {
        localStorage.setItem("token", token);
        localStorage.setItem("tokenDate", new Date());
      }
    }

    this.state = {
      login: "wait",
    };
  }

  componentDidMount() {
    const href = window.location.href;

    if (href.includes('thefameusmedia')){
      // update document title
      document.title = "The FameusMedia";
      // update favicon icon
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = '/assets/img/logo/thefameusmedia-icon.png';
      setInterval(() => {
          // update document title
          document.title = "The FameusMedia";
          // update favicon icon
          let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = '/assets/img/logo/thefameusmedia-icon.png';
        }, 1000);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    if (window.location.href.split("/")[3] === "play") {
      return;
    }

    Axios.post(server + "/account/verify", {}, config)
      .then((rsp) => {
        if (
          rsp.data.hasOwnProperty("is_user") ||
          rsp.data.hasOwnProperty("is_enterprise")
        ) {
          this.setState({
            login: true,
          });
          if (window.location.href.includes("/auth/")) {
            window.location.href = "/dashboard";
          }
        } else {
          window.location.href = auth + "/logout";
        }
      })
      .catch((error) => {
        this.setState({
          login: false,
        });
        window.location.href = auth + "/logout";
      });
  }

  render() {
    const { login } = this.state;
    if (login !== "wait") {
      if (!login) {
        window.location.href = auth + "/auth/signage";
      }
    }
    return (
      <Fragment>
        <BrowserRouter>
          <Fragment>
            {login === "wait" || login === false ? "" : <Topbar />}
            <div
              className={
                login === "wait" || login === false
                  ? "container-fluid d-none"
                  : "container-fluid"
              }
            >
              <div className="row">
                <Sidebar />
                <Switch>
                  <Route path="/" component={DashboardRouter} />
                </Switch>
              </div>
            </div>
          </Fragment>
        </BrowserRouter>
      </Fragment>
    );
  }
}
