import React, { PureComponent } from "react";
import Axios from "axios";
import Spinner from "../../components/Spinner";
import { server, config, signage_path } from "../../routes/env";
import { Notification } from "react-notification";
import resetForm from "../../components/ResetForm";

export default class UserSettings extends PureComponent {
  state = {
    user_id: this.props.match.params.user_id,
    user: [],
    status: false,
    file_control: false,

    logs: [],
    next: null,
    total_logs: 0,
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    show_more_button: "Show more",

    // LODERS
    update_profile_loader: "",
    add_screen_loader: "",
    change_password_loader: "",

    notification: false,
    message: "",
  };

  componentDidMount = async () => {
    await Axios.get(
      server + "/enterprise/user/" + this.state.user_id,
      config
    ).then((rsp) => {
      document.title = rsp.data.user.name + " - Signage Cloud Enterprise";
      this.setState({
        user: rsp.data.user,
        status: rsp.data.user.is_active,
        file_control: rsp.data.user.enterprise_control,
      });
    });
    await this.read_logs("/enterprise/logs/" + this.state.user_id);
  };

  read_logs = (url, search = null) => {
    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        total_logs: rsp.data.count,
        logs: search
          ? rsp.data.results
          : this.state.logs.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^\/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  update_profile = async (event) => {
    event.preventDefault();
    this.setState({
      update_profile_loader: <Spinner />,
    });

    const params = {
      user_id: this.state.user_id,
      name: event.target.full_name.value,
      status: this.state.status,
      file_control: this.state.file_control,
    };

    Axios.put(server + "/enterprise/user/update_profile", params, config)
      .then((rsp) => {
        this.setState({
          update_profile_loader: "",
          notification: true,
          message: rsp.data.detail,
        });
      })
      .catch((error) => {
        this.setState({
          update_profile_loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  add_screen = (event) => {
    event.preventDefault();
    this.setState({
      add_screen_loader: <Spinner />,
    });

    const params = {
      user_id: this.state.user_id,
      otp: parseInt(event.target.pairing_code.value),
      name: event.target.screen_name.value,
    };

    Axios.post(server + "/enterprise/screen/create", params, config)
      .then((rsp) => {
        resetForm(1);
        this.setState({
          message: rsp.data.detail,
          add_screen_loader: "",
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
          add_screen_loader: "",
        });
      });
  };

  update_password = (event) => {
    event.preventDefault();
    this.setState({
      change_password_loader: <Spinner />,
    });

    const params = {
      user_id: this.state.user_id,
      new_password: event.target.new_password.value,
    };

    if (event.target.cnf_password.value !== event.target.new_password.value) {
      this.setState({
        message: "Passwords do not match.",
        change_password_loader: "",
        notification: true,
      });
      return;
    }

    Axios.post(server + "/enterprise/update_password", params, config)
      .then((rsp) => {
        resetForm(2);
        this.setState({
          message: rsp.data.detail,
          change_password_loader: "",
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
          change_password_loader: "",
        });
      });
  };

  getAccess = async () => {
    this.setState({
      accessLoader: <Spinner />,
    });
    await Axios.get(
      server + "/enterprise/user/get-token/" + this.state.user_id,
      config
    )
      .then((rsp) => {
        this.setState({
          accessLoader: "",
        });
        window.open(signage_path + rsp.data.token, "_blank");
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
          accessLoader: "",
        });
      });
  };

  render() {
    const {
      user,
      logs,
      notification,
      next,
      message,
      update_profile_loader,
      add_screen_loader,
      change_password_loader,
      show_more_button,
      status,
      file_control,
      accessLoader,
    } = this.state;

    var status_text = status ? "Active" : "Inactive";
    var file_control_text = file_control
      ? "Allowed Files (No need to verify uploded files)"
      : "Not Allowed (Need to verify uploaded files.)";

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={4000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">{user.name}</h3>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-md-6">
            <div className="card mt-4">
              <div className="card-header bg-transparent border-0 py-4">
                <h6 className="pb-0 mb-0 text-uppercase">
                  Update User Profile
                </h6>
              </div>
              <div className="card-body">
                <form onSubmit={this.update_profile}>
                  <div className="form-group">
                    <label htmlFor="full_name">Full Name</label>
                    <input
                      type="text"
                      name="full_name"
                      id="full_name"
                      defaultValue={user.name}
                      className="form-control"
                      required
                      autoFocus={true}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      defaultValue={user.email}
                      required
                      disabled={true}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="status">Enable / Disable User</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="status"
                        checked={status}
                        onChange={() =>
                          this.setState({ status: status ? false : true })
                        }
                      />
                      <label className="form-check-label" htmlFor="status">
                        {status_text}
                      </label>
                    </div>
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="status">File Control</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="status"
                        checked={file_control}
                        onChange={() =>
                          this.setState({
                            file_control: file_control ? false : true,
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="status">
                        {file_control_text}
                      </label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <small className="text-muted">
                      <b>Tip:</b> Make sure changes are done for correct user
                    </small>
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm rounded-custom px-3 w-100"
                    >
                      Update Profile {update_profile_loader}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mt-4">
              <div className="card-header bg-transparent border-0 py-4">
                <h6 className="pb-0 mb-0 text-uppercase">Add Screen</h6>
              </div>
              <div className="card-body">
                <form action="" onSubmit={this.add_screen}>
                  <div className="form-group">
                    <label htmlFor="pairing_code">Pairing Code</label>
                    <input
                      type="number"
                      name="pairing_code"
                      id="pairing_code"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="screen_name">Screen Name</label>
                    <input
                      type="text"
                      name="screen_name"
                      id="screen_name"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <small>
                    <a
                      href="javascipt:void(0)"
                      className="text-decoration-none"
                    >
                      Need help?
                    </a>
                  </small>
                  <div className="my-4">
                    <small className="text-muted">
                      <b>Tip:</b> Make sure you are adding the screen to the
                      right user.
                    </small>
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm rounded-custom px-3 w-100"
                    >
                      Add Screen {add_screen_loader}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className="card mt-4">
              <div className="card-header bg-transparent border-0 py-4">
                <h6 className="pb-0 mb-0 text-uppercase">Update Password</h6>
              </div>
              <div className="card-body">
                <form onSubmit={this.update_password}>
                  <div className="form-group">
                    <label htmlFor="new_password">New Password</label>
                    <input
                      type="password"
                      name="new_password"
                      id="new_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="cnf_password">Confirm Password</label>
                    <input
                      type="password"
                      name="cnf_password"
                      id="cnf_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="my-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm rounded-custom px-3 w-100"
                    >
                      Change Password {change_password_loader}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className="card mt-4">
              <div className="card-header bg-transparent border-0 py-4">
                <h6 className="pb-0 mb-0 text-uppercase">User Logs</h6>
              </div>
              <div className="card-body">
                Last login:{" "}
                <b>
                  {user.hasOwnProperty("last_login")
                    ? user.last_login !== null
                      ? `[ ${new Date(
                          user.last_login
                        ).toLocaleDateString()} ${new Date(
                          user.last_login
                        ).toLocaleTimeString()} ]`
                      : "N/A"
                    : "N/A"}
                </b>
                <div className="mt-2 mb-1">
                  <strong>
                    <small className="text-uppercase">
                      {" "}
                      <i>File logs</i>{" "}
                    </small>
                  </strong>
                </div>
                {logs.map((data, index) => (
                  <div key={index}>
                    [{new Date(data.content.created).toLocaleString()}]{" "}
                    {data.content.name}{" "}
                    <a rel="norefferer" target="_blank" href={data.content.url}>
                      open
                    </a>{" "}
                  </div>
                ))}
                <button
                  className="btn btn-white btn-sm rounded-custom border mt-4 px-3"
                  onClick={next == null ? null : () => this.read_logs(next)}
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
