import React, { PureComponent } from "react";

export default class FAQs extends PureComponent {
  componentDidMount = () => {
    document.title = "FAQs - Signage Cloud";
  };
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">FAQs</h3>
          </div>
        </div>
        <div className="row justify-content-start mt-4 mb-5">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body px-5">
                <div className="header mt-md-5 text-center">
                  <div className="header-body">
                    <h4 className="header-title"> Cloud Signage FAQs </h4>
                    <p> Frequently asked questions by vendors and users. </p>
                  </div>
                </div>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      How to connect a new screen?
                    </h5>
                  </div>
                </div>
                <p>
                  You will see an <b>Add screen</b> button above the screen’s
                  table. If you have a licence you can proceed to add a screen
                  and enter new pairing code displayed on Signage App Startup
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      What happens when I delete a screen?
                    </h5>
                  </div>
                </div>
                <p>
                  The connected screen will be deleted as soon as the screen
                  gets a delete request. If the screen is offline while being
                  removed from the dashboard it will be deleted after it gets
                  online. Delete Screen command will delete all the local data
                  from the connected screen and display a fresh pairing code.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title"> Can I Rotate Screen? </h5>
                  </div>
                </div>
                <p>
                  When you click the screen's setting button from the screen
                  table you will see an option to choose the rotation of the
                  screen display. Note: This only works if your screen has
                  orientation rotation enabled.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      What does Status mean in the screen’s table?
                    </h5>
                  </div>
                </div>
                <p>
                  Online means the respective screen is connected to the Cloud
                  Signage Server and ready to receive updates you make.
                  <br /> Offline means screen is currently not available to get
                  updated for reasons like Internet Poor Internet Connection,
                  Signage App is not open.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      Can I change the Screen Name?
                    </h5>
                  </div>
                </div>
                <p>
                  There is a menu in the screen’s <b>setting</b> tab to Change
                  Screen’s Name and keep it as you like.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      I see No Playlist on my screen?
                    </h5>
                  </div>
                </div>
                <p> This may happen in the following cases : </p>
                <ul>
                  <li>If you have not assigned a playlist to the screen.</li>
                  <li>If you have assigned an empty playlist to the screen.</li>
                  <li>
                    If you the assigned playlist is deleted from the server.
                  </li>
                </ul>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      Can I customize my dashboard?
                    </h5>
                  </div>
                </div>
                <p>
                  There are many options for you to change how your dashboard
                  looks just click on the Customize Button from Navigation bar
                  and apply the layout you like.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title"> What is a playlist? </h5>
                  </div>
                </div>
                <p>
                  A collection of Images and Videos that play in sequence and
                  loop is a playlist. Every Screen needs a playlist to be
                  assigned which can be played in the loop anytime signage app
                  is on.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      How to add scrolling text to a screen?
                    </h5>
                  </div>
                </div>
                <p>
                  You will need to create a new text from the New Text button in
                  the content library menu.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      How do I customize scrolling text Size, Color and
                      Background Color?
                    </h5>
                  </div>
                </div>
                <p>
                  Filter the content library with Text and click on the pencil
                  button to customize the scrolling text as per your need. You
                  can see the preview of how scrolling text looks like on the
                  screen.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      What file formats are accepted?
                    </h5>
                  </div>
                </div>
                <p>
                  Video: mp4 <br /> Image: JPG, PNG
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      Can different Content be Played on different screens?
                    </h5>
                  </div>
                </div>
                <p>
                  Yes! Everything that plays on Cloud Signage Screen is a
                  playlist. You can make as many playlists you want and assign
                  different playlists to different screens.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      What types of content can I upload?
                    </h5>
                  </div>
                </div>
                <p>
                  Videos and Images and Scrolling text that can be created from
                  the dashboard.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      Does the screen need a stable internet connection?
                    </h5>
                  </div>
                </div>
                <p>
                  You only need an internet connection when you wish to update
                  the playlists or change content. Signage will keep showing
                  assigned playlist in offline mode till it gets online.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      How many screens can I add on my account?
                    </h5>
                  </div>
                </div>
                <p>
                  Cloud Signage allows you to add as many screens as you want
                  as long as you have a licence to add more screens.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      I want to suggest an improvement and request a new
                      feature?
                    </h5>
                  </div>
                </div>
                <p>
                  In the report section, you can drop anything you have to say.
                  We will look into the reports made by you and connect to you
                  in that regard.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      Recommended Image/Video Size?
                    </h5>
                  </div>
                </div>
                <p>
                  Most Screens have 16:9, 9:16 display so we recommend 1440 by
                  2560, 2560 by 1440 for Images and 1080 by 1920, 1920 by 1080
                  for Video. <br /> Additional Info: Your Videos are recommended
                  to be of size 40MB or less so they transfer to screens faster
                  and upload smoothly.
                  <br /> Frame Rate: 30 FPS is recommended for long videos with
                  high quality.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      How do I assign the same playlist to multiple screens at
                      the same time?
                    </h5>
                  </div>
                </div>
                <p>
                  In the playlists option from the navigation bar, you will have
                  a button to “Add playlist to screens” which can help you
                  assign the same playlist content to your screens at the same
                  time.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title"> Will I get GST Bill? </h5>
                  </div>
                </div>
                <p>You can request our executive.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
