import * as React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Image, Link2, Loader, Video } from "react-feather";
import { Notification } from "react-notification";
import { MapPin } from "react-feather";
import "chart.js";
import { AreaChart } from "react-chartkick";
import { Trash2 } from "react-feather";
import Spinner from "../../components/Spinner";
import hideModal from "../../components/HideModal";
import {
  getCovertedAnalyticsDuration,
  timeConvert,
} from "../../components/helper";
import { server, config } from "../../routes/env";
import Modal from "../../components/modal";
// FLAT PICKER DATE PICKER
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import SharedAccess from "./sharedAccess";

export default class ScreenSettings extends React.PureComponent {
  state = {
    screen_id: this.props.match.params.screen_id,

    // Default data load status
    is_screen_loaded: false,
    is_playlists_loaded: false,
    is_scrollers_loaded: false,
    is_layouts_loaded: false,
    is_analytics_enable: false,
    is_content_fit: false,
    user_type: "",

    // Data containers
    screen: [],
    layouts: [],
    playlists: [],
    scrollers: [],
    sharedAccess: [],

    // Public vars
    loader: "",
    message: "",
    notification: false,
    analytics: false,
    preview: false,

    // Current Layout data
    current_layout_id: "",
    current_layout_banner: "",

    // Current Plylists & Scrollers data
    active_playlists: [],
    active_scrollers: [],

    // Preview Playlists & Scrollers data
    preview_playlists: [],
    preview_scrollers: [],

    // Chart data
    analytics_data: [],
    total_analytics_duration: 0,
    analytics_filter_days: 0,
    total_analytics_impressions: 0,

    // Scheduling
    is_scheduling: false,
    scheduling_type: "primary",
    next: null,
    total_schedules: 0,
    schedules: [],
    show_more_button: "Show more",

    // FLAT PICKER
    schedule_date: new Date(),
    time_start: new Date(),
    time_end: new Date(),

    // export report
    reportLoader: "",
  };

  analyticsOnClick = () => {
    this.readAnalytics();
    document.getElementById("analytics").classList.toggle("d-none");
    document.getElementById("preview").classList.add("d-none");
    document.getElementById("schedule").classList.add("d-none");
  };

  previewOnClick = () => {
    document.getElementById("preview").classList.toggle("d-none");
    document.getElementById("analytics").classList.add("d-none");
    document.getElementById("schedule").classList.add("d-none");
    this.preview();
  };

  scheduleOnClick = () => {
    this.readSchedule(
      "/signage/screen/schedule/read/" + this.state.screen_id,
      true
    );
    document.getElementById("schedule").classList.toggle("d-none");
    document.getElementById("analytics").classList.add("d-none");
    document.getElementById("preview").classList.add("d-none");
  };

  changeLayout = (banner, id) => {
    this.setState({
      current_layout_banner: banner,
      current_layout_id: id,
    });
    hideModal(0);
  };

  componentDidMount = async () => {
    document.title = "Screen Settings - Signage Cloud";
    await Axios.post(server + "/account/verify", {}, config).then((rsp) => {
      if (rsp.data.hasOwnProperty("is_user")) {
        this.setState({
          user_type: "user",
        });
      } else {
        this.setState({
          user_type: "enterprise",
        });
      }
    });

    // Read Screen Information
    await Axios.get(
      server + "/signage/screen/read/" + this.state.screen_id + "/",
      config
    )
      .then((rsp) => {
        Axios.get(
          server +
            "/signage/screen/layout/read?orientation=" +
            rsp.data.screen.settings.layout_orientation,
          config
        ).then((rsp) => {
          this.setState({
            layouts: rsp.data.layouts,
            is_layouts_loaded: true,
          });
        });

        var active_playlists = [],
          active_scrollers = [];

        rsp.data.screen.content.forEach((data, index) => {
          if (data.playlist !== null) {
            active_playlists.push(data.playlist);
          } else {
            active_scrollers.push(data.scroller);
          }
        });

        this.setState({
          screen: rsp.data.screen,
          is_screen_loaded: true,
          current_layout_id: rsp.data.screen.settings.layout_id,
          current_layout_banner: rsp.data.screen.settings.layout_banner,
          is_analytics_enable: rsp.data.screen.settings.is_analytics_enable,
          is_content_fit: rsp.data.screen.settings.is_fit,
          active_playlists,
          active_scrollers,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    // Reading user playlists data
    await Axios.get(server + "/signage/playlist/read?records_all", config).then(
      (rsp) => {
        this.setState({
          playlists: rsp.data.playlists,
          is_playlists_loaded: true,
        });
      }
    );

    // Reading Scrollers data
    await Axios.get(
      server + "/signage/content/text/read?records_all",
      config
    ).then((rsp) => {
      this.setState({
        scrollers: rsp.data.scrollers,
        is_scrollers_loaded: true,
      });
    });
  };

  update = (event) => {
    event.preventDefault();
    this.setState({
      loader: <Spinner />,
    });

    var current_playlists = [],
      current_scrollers = [];

    this.state.layouts.forEach((data) => {
      if (data.id === this.state.current_layout_id) {
        for (var i = 0; i < data.playlist; i++) {
          current_playlists.push(document.getElementById("playlist" + i).value);
        }
        for (i = 0; i < data.scroller; i++) {
          current_scrollers.push(document.getElementById("scroller" + i).value);
        }
      }
    });

    const params = {
      id: this.state.screen_id,
      schedule_type: this.state.scheduling_type,
      schedule_start:
        this.state.scheduling_type === "primary"
          ? null
          : this.state.scheduling_type === "permanent"
          ? this.state.schedule_date.length === 1
            ? this.state.schedule_date[0]
            : this.state.schedule_date
          : this.state.time_start.toLocaleTimeString(),
      schedule_end:
        this.state.scheduling_type === "primary"
          ? null
          : this.state.time_end === null
          ? null
          : this.state.time_end.toLocaleTimeString(),
      name: event.target.screen_name.value,
      layout: this.state.current_layout_id,
      playlist: current_playlists,
      scroller: current_scrollers,
      rotation_config: parseInt(event.target.rotation_config.value),
      is_analytics: this.state.is_analytics_enable,
      is_fit: this.state.is_content_fit,
    };

    const new_screen_name = event.target.screen_name.value;

    var url = server;

    if (this.state.scheduling_type === "primary") {
      url += "/signage/screen/update";
    } else {
      url += "/signage/screen/schedule/create";
    }

    Axios.put(url, params, config)
      .then((rsp) => {
        var screen = this.state.screen;
        screen.name = new_screen_name;
        this.setState({
          screen,
          loader: "",
          notification: true,
          message: rsp.data.detail,
        });
        this.readSchedule(
          "/signage/screen/schedule/read/" + this.state.screen_id,
          true
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  readAnalytics = (type = "tw") => {
    this.setState({
      analytics: false,
      analytics_filter_days: type === "tw" ? 7 : 30,
    });

    // Reading Analytics
    Axios.get(
      server + "/signage/analytics/screen/" + this.state.screen_id + "/" + type,
      config
    ).then((rsp) => {
      this.setState({
        analytics_data: rsp.data.impressions,
        total_analytics_duration: getCovertedAnalyticsDuration(
          rsp.data.duration
        ),
        total_analytics_impressions: rsp.data.total_impressions,
        analytics: true,
      });
    });
  };

  preview = async () => {
    var current_playlists = [],
      current_scrollers = [];

    this.state.layouts.forEach((data) => {
      if (data.id === this.state.current_layout_id) {
        for (var i = 0; i < data.playlist; i++) {
          current_playlists.push(document.getElementById("playlist" + i).value);
        }
        for (i = 0; i < data.scroller; i++) {
          current_scrollers.push(document.getElementById("scroller" + i).value);
        }
      }
    });

    current_playlists = current_playlists.filter((data, index) => data !== "");
    current_scrollers = current_scrollers.filter((data, index) => data !== "");

    var params = {
      playlists: current_playlists,
    };

    await Axios.post(server + "/signage/playlist/preview", params, config)
      .then((rsp) => {
        this.setState({
          preview_playlists: rsp.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    params = {
      scrollers: current_scrollers,
    };

    await Axios.post(server + "/signage/content/text/preview", params, config)
      .then((rsp) => {
        this.setState({
          preview_scrollers: rsp.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changeSchedule = (scheduling_type) => {
    this.setState({
      scheduling_type,
    });
  };

  readSchedule = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        total_schedules: rsp.data.count,
        schedules: search
          ? rsp.data.results
          : this.state.schedules.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  deleteSchedule = (id) => {
    this.setState({
      schedules: this.state.schedules.filter((data) => data.id !== id),
    });
    Axios.delete(server + "/signage/screen/schedule/delete/" + id, config)
      .then((rsp) => {
        this.setState({
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((error) => {});
  };

  shareAccess = (e) => {
    e.preventDefault();
    this.setState({
      loader: <Spinner />,
    });

    const params = {
      email: e.target.email.value,
      screen: this.state.screen_id,
    };

    Axios.post(server + "/signage/screen/access/share", params, config)
      .then((rsp) => {
        this.setState({
          message: rsp.data.detail,
          notification: true,
          loader: "",
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.detail,
          notification: true,
          loader: "",
        });
      });
  };

  exportStatusReport = () => {
    this.setState({
      reportLoader: <Spinner />,
    });

    Axios.get(
      server + "/signage/screen/status/report/" + this.state.screen_id,
      config
    )
      .then((rsp) => {
        this.setState({
          reportLoader: "",
        });
        const reports = rsp.data.report;
        var processedReports = [];

        for (let i = 0; i < reports.length; i++) {
          if (reports[i])
            processedReports.push({
              Screen__Name: reports[i].screen?.name,
              playlist: reports[i].screen?.content.join(', '),
              Status: reports[i].status ? "Online" : "Offline",
              Date__Time: new Date(reports[i].created).toString(),
            });
        }

        const XLSX = window.XLSX;
        var ws = XLSX.utils.json_to_sheet(processedReports);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");
        XLSX.writeFile(wb, new Date().toLocaleDateString() + ".xlsx");
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          reportLoader: "",
        });
      });
  };

  render() {
    const {
      // Default data load status
      is_screen_loaded,
      is_playlists_loaded,
      is_scrollers_loaded,
      is_layouts_loaded,
      is_analytics_enable,
      is_content_fit,
      user_type,

      // Data containers
      screen,
      layouts,
      playlists,
      scrollers,

      // Public Vars
      loader,
      message,
      notification,
      analytics,

      // Current Layout data
      current_layout_id,
      current_layout_banner,

      // Current Plylists & Scrollers data
      active_playlists,
      active_scrollers,

      // Preview Playlists & Scrollers data
      preview_playlists,
      preview_scrollers,

      // Analytic Chart Data
      analytics_data,
      total_analytics_duration,
      analytics_filter_days,
      total_analytics_impressions,

      // Scheduling
      scheduling_type,
      schedules,
      next,
      show_more_button,
      total_schedules,

      // FLAT PICKER
      schedule_date,
      time_start,
      time_end,

      reportLoader,
    } = this.state;

    // These are the variable which will store data about how many playlist and scroller fields to be render
    var render_playlists_field = [];
    var render_scrollers_field = [];

    if (is_layouts_loaded) {
      layouts
        .filter((data) => data.id === current_layout_id)
        .forEach((data) => {
          for (var i = 0; i < data.playlist; i++) {
            render_playlists_field.push(i);
          }
          for (i = 0; i < data.scroller; i++) {
            render_scrollers_field.push(i);
          }
        });
    }

    return is_screen_loaded &&
      is_layouts_loaded &&
      is_playlists_loaded &&
      is_scrollers_loaded ? (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        {/* Notification component */}
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={3000}
          onDismiss={() => this.setState({ notification: false })}
        />

        {/* Page Title Component*/}
        <div
          className={
            "card mt-4 shadow-sm " + (is_screen_loaded ? "" : "d-none")
          }
        >
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">{screen.name}</h3>
          </div>
        </div>

        {/* Screen Settings component */}
        <form onSubmit={this.update}>
          <div className="row gx-4 gy-4">
            <div className="col-12 pb-2">
              {/* <div className="card mt-4"> */}
              {/* <div className="card-body p-3"> */}
              <ul className="list-group border-0 list-group-horizontal mt-5">
                <Link
                  to="#"
                  className={
                    scheduling_type === "primary"
                      ? "text-decoration-none text-white rounded-pill mr-2 shadow-sm"
                      : "text-decoration-none text-dark rounded-pill mr-2 shadow-sm"
                  }
                  onClick={() => this.changeSchedule("primary")}
                >
                  <li
                    className={
                      scheduling_type === "primary"
                        ? "list-group-item btn btn-primary btn-sm rounded-pill"
                        : "list-group-item border-0"
                    }
                  >
                    Primary
                  </li>
                </Link>
                {/* <Link
                  to="#"
                  className={
                    scheduling_type === "permanent"
                      ? "text-decoration-none text-white rounded-pill  mr-2 shadow-sm"
                      : "text-decoration-none text-dark rounded-pill  mr-2 shadow-sm"
                  }
                  onClick={() => this.changeSchedule("permanent")}
                >
                  <li
                    className={
                      scheduling_type === "permanent"
                        ? "list-group-item btn btn-primary btn-sm rounded-pill"
                        : "list-group-item border-0"
                    }
                  >
                    Schedule
                  </li>
                </Link> */}

                <Link
                  to="#"
                  className="text-decoration-none text-dark rounded-pill  mr-2 shadow-sm"
                  onClick={this.exportStatusReport}
                >
                  <li className="list-group-item border-0">
                    Online/Offline Report {reportLoader}
                  </li>
                </Link>

                {user_type === "enterprise" ? (
                  <Link
                    to="#"
                    className={
                      scheduling_type === "share"
                        ? "text-decoration-none text-white rounded-pill shadow-sm"
                        : "text-decoration-none text-dark rounded-pill shadow-sm"
                    }
                    onClick={() => this.changeSchedule("share")}
                    data-toggle="modal"
                    data-target="#shareAccess"
                  >
                    <li
                      className={
                        scheduling_type === "share"
                          ? "list-group-item btn btn-primary btn-sm rounded-pill"
                          : "list-group-item border-0"
                      }
                    >
                      Share Access
                    </li>
                  </Link>
                ) : (
                  ""
                )}
              </ul>
            </div>

            <div className="col-lg-4" style={{ overflowX: "hidden" }}>
              <div className="card py-4">
                <div className="card-body p-3">
                  <div className="mb-3 text-center">
                    <Link
                      to="#"
                      data-toggle="modal"
                      data-target="#choose_layout"
                    >
                      <img
                        src={"/assets/img/layouts/" + current_layout_banner}
                        alt={current_layout_banner}
                        className="rounded-custom"
                        style={{
                          height: "auto",
                          maxWidth: "250px",
                          maxHeight: "270px",
                        }}
                      />
                    </Link>
                    <br /> <br />
                    <Link
                      to="#"
                      onClick={this.scheduleOnClick}
                      className="text-decoration-none"
                    >
                      <small>Check all schedules</small>
                    </Link>
                  </div>
                  <div className="form-group pt-3 text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm rounded-custom px-3 my-2 mr-2"
                      data-toggle="modal"
                      data-target="#choose_layout"
                    >
                      Change Layout
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm rounded-custom my-2 px-3"
                      onClick={this.analyticsOnClick}
                    >
                      Show Analytics
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card py-4 p-3">
                <div className="card-body p-3">
                  <div className="row gy-5">
                    <div className="col-lg-6">
                      {/* Screen Configurations */}
                      <div className="card-title h5 text-muted d-inline-block mr-2">
                        Screen Configurations
                      </div>
                      {screen.settings.status ? (
                        <span className="badge bg-success float-right">
                          Online
                        </span>
                      ) : (
                        <span className="badge bg-danger float-right">
                          Offline
                        </span>
                      )}
                      <div className="form-group mt-4 text-primary">
                        <span>
                          Last Available -{" "}
                          {new Date(
                            screen.settings.last_response
                          ).toDateString() +
                            ", " +
                            timeConvert(
                              new Date(screen.settings.last_response)
                                .toTimeString()
                                .substr(0, 5)
                            )}
                        </span>{" "}
                        <br />(
                        {screen.settings.lat !== null &&
                        screen.settings.lat !== "notGranted" ? (
                          <a
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                            // href="http://www.google.com/maps/place/49.46800006494457,17.11514008755796
                            href={
                              "http://www.google.com/maps/place/" +
                              screen.settings.lat +
                              "," +
                              screen.settings.long
                            }
                            className="form-check-label text-decoration-none"
                            htmlFor="status"
                          >
                            Location on google map
                          </a>
                        ) : (
                          <span>
                            Location{" "}
                            {screen.settings.lat === null
                              ? "Not Available"
                              : screen.settings.lat === "notGranted"
                              ? "Location Permission not Granted"
                              : ""}
                          </span>
                        )}
                        )
                      </div>
                      <div className="form-group mt-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={is_analytics_enable}
                            onChange={() =>
                              this.setState({
                                is_analytics_enable: is_analytics_enable
                                  ? false
                                  : true,
                              })
                            }
                            id="status"
                          />
                          <label className="form-check-label" htmlFor="status">
                            {is_analytics_enable ? "Active" : "Inactive"}{" "}
                            (Enable / Disable Analytics)
                          </label>
                        </div>
                      </div>

                      <div className="form-group mt-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={is_content_fit}
                            onChange={() =>
                              this.setState({
                                is_content_fit: is_content_fit ? false : true,
                              })
                            }
                            id="status"
                          />
                          <label className="form-check-label" htmlFor="status">
                            {is_content_fit ? "Active" : "Inactive"} (Fit
                            Content)
                          </label>
                        </div>
                      </div>

                      <div className="form-group pt-3">
                        <label htmlFor="rotate_screen">Rotate Screen</label>
                        <select
                          name="rotation_config"
                          id="rotation_config"
                          className="form-control rounded-custom"
                          defaultValue={screen.settings.rotation_config}
                        >
                          <option value="0">Normal</option>
                          <option value="90">Rotate Right</option>
                          <option value="180">Rotate Opposite</option>
                          <option value="270">Rotate Left</option>
                        </select>
                      </div>
                      <div className="form-group pt-3">
                        <label htmlFor="screen_name">Screen Name</label>
                        <input
                          type="text"
                          name="screen_name"
                          id="screen_name"
                          disabled={
                            scheduling_type === "permanent" ||
                            scheduling_type === "routine"
                              ? true
                              : false
                          }
                          defaultValue={screen.name}
                          className="form-control rounded-custom"
                          required
                        />
                      </div>

                      {/* FLAT PICKER / SCHEDULE CONFIGS */}
                      {scheduling_type === "permanent" ? (
                        <div className="form-group pt-3">
                          <label htmlFor="screen_name">
                            Schedule Start Date & Time (24 hr Format)
                          </label>
                          <Flatpickr
                            data-enable-time
                            value={schedule_date}
                            options={{ minDate: new Date(), time_24hr: true }}
                            className="form-control bg-transparent"
                            onChange={(schedule_date) => {
                              this.setState({ schedule_date });
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {scheduling_type === "routine" ? (
                        <>
                          <div className="text-center mt-5">Scheduling</div>
                          <div className="form-group pt-3">
                            <label htmlFor="screen_name">Schedule Type</label>
                            <select name="" id="" className="form-control">
                              <option value="">Daily</option>
                              <option value="">Weekly</option>
                            </select>
                          </div>
                          <div className="form-group pt-3">
                            <label htmlFor="screen_name">
                              Schedule Start Time (24 hr Format)
                            </label>
                            <Flatpickr
                              options={{
                                altInput: true,
                                altFormat: "H :i",
                                noCalendar: true,
                                time_24hr: true,
                                enableTime: true,
                              }}
                              value={time_start}
                              className="form-control bg-transparent"
                              onChange={(time_start) => {
                                this.setState({ time_start });
                              }}
                            />
                          </div>
                          <div className="form-group pt-3 mb-4">
                            <label htmlFor="screen_name">
                              Schedule End Time (24 hr Format)
                            </label>
                            <Flatpickr
                              className="form-control bg-transparent"
                              options={{
                                altInput: true,
                                altFormat: "H :i",
                                noCalendar: true,
                                time_24hr: true,
                                enableTime: true,
                              }}
                              value={time_end}
                              onChange={(time) => {
                                this.setState({ time_end: time });
                              }}
                            />
                          </div>{" "}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="mt-4">
                        <small className="text-muted">
                          All changes of screen settings, playlists, and
                          scrollers will apply, please check before updating the
                          screen.
                        </small>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm rounded-custom px-3 w-100"
                        >
                          Update Screen & Media Configurations {loader}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      {/* Media Configurations */}
                      <div className="card-title h5 text-muted d-inline-block">
                        Media Configurations
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary d-inline-block float-right"
                        onClick={this.previewOnClick}
                      >
                        Preview
                      </button>

                      {/* Dynamic Playlist Field Rendring */}
                      {render_playlists_field.map((d, i) => (
                        <div className="form-group pt-3" key={i}>
                          <label htmlFor="rotate_screen">
                            Choose Playlist {i + 1}
                          </label>
                          <select
                            name={"playlist" + i}
                            id={"playlist" + i}
                            defaultValue={
                              active_playlists[i] !== undefined
                                ? active_playlists[i].id
                                : false
                            }
                            className="form-control rounded-custom"
                          >
                            <option value="">Choose Playlist</option>
                            {playlists.map((data, index) => (
                              <>
                                <option value={data.id} key={index}>
                                  {data.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      ))}

                      {/* Dynamic Scroller Field Rendring */}
                      {render_scrollers_field.map((d, i) => (
                        <div className="form-group pt-3" key={i}>
                          <label htmlFor="rotate_screen">
                            Choose Scroller {i + 1}
                          </label>
                          <select
                            name={"scroller" + i}
                            id={"scroller" + i}
                            defaultValue={
                              active_scrollers[i] !== undefined
                                ? active_scrollers[i].id
                                : false
                            }
                            className="form-control rounded-custom"
                          >
                            <option value="">Choose Scroller</option>
                            {scrollers.map((data, index) => (
                              <>
                                <option value={data.id} key={index}>
                                  {data.title}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        {/* Choose Layout Modal */}
        <Modal id="choose_layout" title="Choose Layout" className="modal-lg">
          <div className="modal-body">
            <div className="pl-3">
              <small className="text-muted">
                <b>Tip: </b>Click on layout to select
              </small>
            </div>
            <div className="row jutify-content-center">
              {layouts.map((data, index) => (
                <div className="col-md-6 text-center">
                  {data.id === current_layout_id ? (
                    <img
                      src={"/assets/img/layouts/" + data.banner}
                      alt={data.banner}
                      key={index}
                      className="d-inline-block m-3 rounded-custom active_layout choose_layout_banner w-50"
                    />
                  ) : (
                    <img
                      src={"/assets/img/layouts/" + data.banner}
                      alt={data.banner}
                      key={index}
                      className="d-inline-block m-3 rounded-custom choose_layout_banner w-50"
                      onClick={() => this.changeLayout(data.banner, data.id)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>

        {/* Schedules */}
        <div className="card mt-4 d-none" id="schedule">
          <div className="card-body">
            <div className="card-header bg-transparent border-0 p-3">
              <div className="card-title h5 text-muted d-inline-block">
                Screen Schedules
              </div>
            </div>
            <div className="card-body">
              <div className="card-body p-0 border-0">
                <div className="table-responsive">
                  <table className="table table-striped table-hover mb-0">
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>Layout</th>
                        <th>Analytics</th>
                        <th>Rotation Config</th>
                        <th>Schedule Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Added On</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedules.map((data, index) => (
                        <tr
                          className="text-center text-muted"
                          key={index}
                          id={"screen_" + index}
                        >
                          <td className="td-pt-3">#{index + 1}</td>
                          <td className="td-pt-3">{data.layout_name}</td>
                          <td className="td-pt-3">
                            {data.is_analytics ? (
                              <span className="badge bg-success">Enabled</span>
                            ) : (
                              <span className="badge bg-danger">Disabled</span>
                            )}
                          </td>
                          <td className="td-pt-3">
                            {data.rotation_config === 0
                              ? "Normal"
                              : data.rotation_config === 90
                              ? "Rotate Right"
                              : data.rotation_config === 180
                              ? "Rotate Opposite"
                              : "Rotate Left"}
                          </td>
                          <td className="td-pt-3">
                            {data.schedule_type.toUpperCase()}
                          </td>
                          <td className="td-pt-3">
                            {data.schedule_type === "permanent"
                              ? new Date(
                                  data.schedule_datetime
                                ).toDateString() +
                                ", " +
                                timeConvert(
                                  new Date(data.schedule_datetime)
                                    .toTimeString()
                                    .substring(0, 5)
                                )
                              : timeConvert(
                                  data.schedule_start.substring(0, 5)
                                )}
                          </td>
                          <td className="td-pt-3">
                            {data.schedule_type === "permanent"
                              ? "--"
                              : timeConvert(data.schedule_end.substring(0, 5))}
                          </td>

                          <td className="td-pt-3">
                            {new Date(data.created).toDateString() +
                              ", " +
                              timeConvert(
                                new Date(data.created)
                                  .toLocaleTimeString()
                                  .substring(0, 5)
                              )}
                          </td>

                          <td>
                            <button
                              className="btn btn-sm btn-danger rounded-custom"
                              onClick={() => this.deleteSchedule(data.id)}
                            >
                              <Trash2 />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer bg-transparent border-0 p-3">
                <div className="row">
                  <div className="col-6 text-muted">
                    <h6 className="h6 pt-2 small">
                      Showing {schedules.length} of {total_schedules}
                    </h6>
                  </div>

                  <div className="col-6 text-right">
                    <button
                      className="btn btn-white btn-sm rounded-custom border px-3"
                      onClick={
                        next == null ? null : () => this.read_screens(next)
                      }
                    >
                      {show_more_button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Media Preview */}
        <div className="card mt-4 d-none" id="preview">
          <div className="card-body">
            <div className="card-header bg-transparent border-0 p-3">
              <div className="card-title h5 text-muted d-inline-block">
                Media Configurations
              </div>
            </div>
            <div className="card-body">
              {preview_playlists.map((playlist, index) => (
                <>
                  <div className="card-title h6 text-muted mb-0">
                    Playlist {index + 1}
                  </div>
                  <div className="row mb-4">
                    {playlist.map((data, i) => (
                      <div className="col-md-2" key={i}>
                        {data.content ? (
                          <div className="card mt-4 border">
                            <img
                              src={
                                data.content.type === "image"
                                  ? data.content.thumbnail
                                  : "/assets/img/banners/video_banner_1.png"
                              }
                              alt=""
                              className="card-img-top wilyer_library_card_cover"
                            />
                            <div className="card-body row gy-2">
                              <div className="col-12">
                                <strong>
                                  {data.content.name.substring(0, 15)}
                                </strong>
                                <br />
                                <small className="text-muted">
                                  {data.content.type === "image" ? (
                                    <span>
                                      <Image /> image
                                    </span>
                                  ) : (
                                    <span>
                                      <Video /> video
                                    </span>
                                  )}
                                </small>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="card mt-4 border">
                            <img
                              src="/assets/img/banners/s2.png"
                              alt=""
                              className="card-img-top wilyer_library_card_cover"
                            />
                            <div className="card-body row gy-2">
                              <div className="col-12">
                                <strong>
                                  {data.web.name.substring(0, 15)}
                                </strong>
                                <br />
                                <small className="text-muted">
                                  <span>
                                    <Link2 /> URL
                                  </span>
                                </small>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ))}

              {preview_scrollers.map((scroller, index) => (
                <>
                  <div className="card-title h6 text-muted" key={index}>
                    Scroller {index + 1}
                  </div>
                  <div className="row my-3">
                    {scroller.map((data, i) => (
                      <div key={i}>
                        <marquee
                          key={"scroller" + index}
                          className="rounded-lg"
                          style={{
                            color: data.color,
                            fontSize: data.font_size + "px",
                            backgroundColor: data.bg_color,
                          }}
                        >
                          {data.content}
                        </marquee>
                      </div>
                    ))}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>

        {/* Analytics */}
        <div className="card mt-4 d-none" id="analytics">
          <div className="card-body">
            <div className="card-header bg-transparent border-0 p-3">
              <div className="card-title h5 text-muted d-inline-block">
                Screen Analytics
              </div>
              <div className="row gy-3 float-right">
                <div className="col-md-4">
                  <select
                    name="rotation_config"
                    id="rotation_config"
                    className="form-control-sm rounded-custom btn-sm btn-primary my-2 mr-2"
                    onChange={(event) => this.readAnalytics(event.target.value)}
                  >
                    <option value="tw">This Week</option>
                    <option value="tm">This Month</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div className={analytics ? "d-none" : ""}>
                <Spinner size={20} color="#888" />
              </div>
              <div className={analytics ? "row mb-5" : "d-none"}>
                <div className="col-lg-4">
                  <div className="card border">
                    <div className="card-body">
                      Total Impressions: {total_analytics_impressions}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card border">
                    <div className="card-body">
                      Time: Last {analytics_filter_days} Days
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card border">
                    <div className="card-body">
                      Content Play: {total_analytics_duration}
                    </div>
                  </div>
                </div>
              </div>
              <div className={analytics ? "" : "d-none"}>
                <AreaChart data={analytics_data} />
              </div>
            </div>
          </div>
        </div>

        {/* Screen Access */}
        {user_type === "enterprise" ? (
          <SharedAccess screen_id={this.state.screen_id} />
        ) : (
          ""
        )}
        <div className="mb-5"></div>

        {/* Share Screen access with sub user */}
        <Modal id="shareAccess" title="Share Screen Access" key="shareAccess">
          <div className="modal-body p-4">
            <form onSubmit={this.shareAccess}>
              <div className="form-group">
                <label htmlFor="email">Sub User Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group float-right mt-3">
                <button
                  type="submit"
                  className="btn btn-primary btn rounded-custom"
                >
                  Give Access {loader}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </main>
    ) : (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mx-auto p-4 w-25 ">
          <div className="row justify-content-center">
            <Spinner size={20} color={"#555"} />
          </div>
        </div>
      </main>
    );
  }
}
