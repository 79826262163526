import React, { Fragment, PureComponent, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { Check, Trash2 } from "react-feather";
import Modal from "../../components/modal";
import Axios from "axios";
import { server, config, upload_path } from "../../routes/env";
import Spinner from "../../components/Spinner";
import { Notification } from "react-notification";
import hideModal from "../../components/HideModal";
import resetForm from "../../components/ResetForm";

function Previews(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg, image/jpg, video/mp4",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file, index) => (
    <div className="col-md-3" key={index}>
      <div
        className="card text-center rounded-custom"
        style={{ position: "relative", width: "100%" }}
      >
        {file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/png" ? (
          <img
            src={file.preview}
            className="wilyer_file_uploading rounded-custom"
            alt="preview"
          />
        ) : (
          <video controls className="wilyer_file_uploading rounded-custom">
            <source src={file.preview} />
          </video>
        )}
        <div className="progress upload_progress_bar">
          <div
            className="progress-bar bg-info progress-bar-striped progress-bar-animated"
            role="progressbar"
            id={"file_" + index}
            style={{ width: "0%" }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            0%
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container p-4">
      <label className="form-control">
        <div {...getRootProps({ className: "dropzone" })}>
          <input name="file" {...getInputProps()} multiple={true} />
        </div>
        <span>Select files to upload</span>
      </label>

      <div className="row g-4 mt-3">{thumbs}</div>
    </section>
  );
}

export default class Screen extends PureComponent {
  state = {
    content: [],
    scrollers: [],
    url: "/enterprise/content",
    current_url: "",

    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    show_more_button: "Show more",
    next: null,
    total_content: 0,
    preview_file_type: "",
    preview_file_name: "",
    preview_file_path: "",
    filter: "banner_and_clips",
    uplaod_button_loader: "",

    notification: false,
    message: "",
    loader: "",
  };

  componentDidMount = async () => {
    document.title = "Content Library - Sinage Cloud Enterprise";
    this.read_content(this.state.url);
  };

  read_content = async (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
      current_url: url,
    });

    Axios.get(server + url, config).then((rsp) => {
      if (this.state.filter === "text") {
        this.setState({
          scrollers: search
            ? rsp.data.results
            : this.state.scrollers.concat(rsp.data.results),
        });
      }

      this.setState({
        total_content: rsp.data.count,
        content: search
          ? rsp.data.results
          : this.state.content.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are catch up!" : "Show more",
      });
    });
  };

  filter = async (event) => {
    event.persist();
    await this.setState({
      filter: event.target.value,
    });

    if (event.target.value === "banner_and_clips") {
      this.read_content(this.state.url, true);
    } else {
      this.read_content("/enterprise/text/read", true);
    }
  };

  search = (event) => {
    if (this.state.filter === "text") {
      this.read_content(
        "/enterprise/text/read?search=" + event.target.value,
        true
      );
    } else {
      this.read_content(this.state.url + "?search=" + event.target.value, true);
    }
  };

  delete_content = async (id) => {
    this.setState({
      content: this.state.content.filter((data) => data.id !== id),
    });
    Axios.delete(server + "/enterprise/content/delete/" + id, config)
      .then((rsp) => {
        this.setState({
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  approve_content = async (id) => {
    Axios.put(server + "/enterprise/content/approve/" + id, {}, config)
      .then((rsp) => {
        var lib_data = this.state.content;

        for (var i = 0; i < lib_data.length - 1; i++) {
          if (lib_data[i].content.id === id) {
            lib_data[i].content.status = true;
            break;
          }
        }

        this.setState({
          message: rsp.data.detail,
          notification: true,
          content: lib_data,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  delete_scroller = (id) => {
    this.setState({
      scrollers: this.state.scrollers.filter((data) => data.id !== id),
    });
    Axios.delete(server + "/signage/content/text/delete/" + id, config)
      .then((rsp) => {
        this.setState({
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  approve_scroller = async (id) => {
    Axios.put(server + "/enterprise/text/approve/" + id, {}, config)
      .then((rsp) => {
        var lib_data = this.state.scrollers;

        for (var i = 0; i < lib_data.length - 1; i++) {
          if (lib_data[i].id === id) {
            lib_data[i].status = true;
            break;
          }
        }
        this.setState({
          message: rsp.data.detail,
          notification: true,
          scrollers: lib_data,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  progress = (progressEvent, location) => {
    var dom = document.getElementById("file_" + String(location));
    dom.style.width = (progressEvent.loaded * 100) / progressEvent.total + "%";
    dom.innerText =
      ((progressEvent.loaded * 100) / progressEvent.total).toFixed() + "%";
  };

  upload = async (event) => {
    event.preventDefault();
    event.persist();

    for (var i = 0; i < event.target.file.files.length; i++) {
      var progressEvent = {
        loaded: 0,
        total: 100,
      };
      this.progress(progressEvent, i);
    }

    this.setState({
      uplaod_button_loader: <Spinner />,
    });

    for (i = 0; i < event.target.file.files.length; i++) {
      var config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => this.progress(progressEvent, i),
      };
      var formData = new FormData();
      formData.append("file", event.target.file.files[i]);
      await Axios.post(
        upload_path + "/signage/content/create",
        formData,
        config
      )
        .then((rsp) => {
          this.setState({
            message: rsp.data.detail,
            notification: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    this.read_content(this.state.url, true, true);
    hideModal(0);
    resetForm(0);
    this.setState({
      uplaod_button_loader: "",
    });
  };

  create_scroller = async (event) => {
    event.preventDefault();
    event.persist();
    this.setState({
      loader: <Spinner />,
    });

    const params = {
      title: event.target.title.value,
      color: event.target.color.value,
      bg_color: event.target.bg_color.value,
      content: event.target.content.value,
      font_size: event.target.font_size.value,
    };

    Axios.post(server + "/signage/content/text/create", params, config)
      .then((rsp) => {
        this.read_content("/signage/content/text/read", true);
        hideModal(1);
        resetForm(1);
        this.setState({
          filter: "text",
          message: rsp.data.detail,
          notification: true,
          loader: "",
        });
      })

      .catch((error) => {
        console.log(error);
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  render() {
    const {
      content,
      scrollers,
      total_content,
      show_more_button,
      next,
      preview_file_name,
      preview_file_type,
      preview_file_path,
      uplaod_button_loader,
      filter,
      message,
      notification,
      loader,
    } = this.state;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={5000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Content Library</h3>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between gy-3">
              <div className="col-md-6 mt-3">
                <button
                  className="btn btn-primary mr-1 mt-1 rounded-custom px-3"
                  data-toggle="modal"
                  data-target="#add_clips_or_banners_modal"
                >
                  Add Clips/Banners
                </button>
                <button
                  className="btn btn-primary mt-1 rounded-custom px-3"
                  data-toggle="modal"
                  data-target="#add_text_scroller_modal"
                >
                  Add Text Scrollers
                </button>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6  mt-2">
                    <input
                      type="text"
                      className="form-control text-muted rounded-custom pl-3"
                      placeholder="Search by file name"
                      onChange={this.search}
                    />
                  </div>
                  <div className="col-md-6  mt-2">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control rounded-custom"
                      onChange={this.filter}
                    >
                      <option value="banner_and_clips">Banners & Clips</option>
                      <option value="text">Text Scrollers</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0 border-0">
            {filter === "banner_and_clips" ? (
              <div className="table-responsive">
                <table className="table table-striped table-hover mb-0">
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>Picture</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>File Name</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Duration</th>
                      <th>Posted</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content.map((data, index) => (
                      <tr key={index}>
                        <td className="pt-4 text-center">#{index + 1}</td>
                        <td className="td-pt-3 text-center">
                          <Link
                            href="#"
                            data-toggle="modal"
                            data-target="#view_file_modal"
                            onClick={() =>
                              this.setState({
                                preview_file_name: data.name,
                                preview_file_type: data.type,
                                preview_file_path: data.url,
                              })
                            }
                          >
                            <img
                              src={
                                data.type === "image"
                                  ? data.thumbnail
                                  : data.type === "video"
                                  ? "/assets/img/banners/s1.png"
                                  : "/assets/img/banners/s3.png"
                              }
                              alt={data.name}
                              style={{ height: "40px", width: "auto" }}
                              className="rounded-custom"
                            />
                          </Link>
                        </td>
                        <td className="pt-4 text-center">{data.username}</td>
                        <td className="pt-4 text-center">
                          <a
                            target="_blank"
                            rel="noopner noreferrer"
                            className="text-decoration-none"
                            href={"mailto:" + data.email}
                          >
                            {data.email}
                          </a>
                        </td>
                        <td className="pt-4 text-center">
                          <span
                            data-toggle="modal"
                            data-target="#view_file_modal"
                            onClick={() =>
                              this.setState({
                                preview_file_name: data.name,
                                preview_file_type: data.type,
                                preview_file_path: data.url,
                              })
                            }
                          >
                            <Link to="#" className="text-decoration-none">
                              {data.name.length > 30 ? data.name.substr(0, 30) + "..." : data.name}
                            </Link>
                          </span>
                        </td>
                        <td className="pt-4 text-center">
                          {data.type === "video" ? <i>Video</i> : <i>Image</i>}
                        </td>
                        <td className="pt-4 text-center">
                          {data.status ? (
                            <span className="badge bg-success">Approved</span>
                          ) : (
                            <span className="badge bg-danger">
                              Not Approved
                            </span>
                          )}
                        </td>
                        <td className="pt-4 text-center">
                          {data.duration === 0 ? "_" : data.duration + "sec"}
                        </td>
                        <td className="pt-4 text-center">
                          {new Date(data.created).toDateString()}
                        </td>
                        <td className="text-center">
                          {data.status ? (
                            <button
                              className="btn btn-sm btn-danger mr-1"
                              onClick={() => this.delete_content(data.id)}
                            >
                              <Trash2 />
                            </button>
                          ) : (
                            <Fragment>
                              <button
                                className="btn btn-sm btn-primary mr-1"
                                onClick={() => this.approve_content(data.id)}
                              >
                                <Check />
                              </button>
                              <button
                                className="btn btn-sm btn-danger mr-1"
                                onClick={() => this.delete_content(data.id)}
                              >
                                <Trash2 />
                              </button>
                            </Fragment>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="row g-4 px-3">
                {scrollers.map((data, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="card mt-4 border">
                      <div className="card-body">
                        <h6 className="text-muted pt-2">{data.title}</h6>
                        <marquee
                          className="rounded-lg"
                          style={{
                            color: data.color,
                            fontSize: data.font_size + "px",
                            backgroundColor: data.bg_color,
                          }}
                        >
                          {data.content}
                        </marquee>

                        <div className="float-right">
                          {data.status ? (
                            <button
                              className="btn btn-sm btn-danger mr-1"
                              onClick={() => this.delete_scroller(data.id)}
                            >
                              <Trash2 />
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-primary mr-1"
                              onClick={() => this.approve_scroller(data.id)}
                            >
                              <Check />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {content.length} of {total_content}
                </h6>
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm rounded-custom border px-3"
                  onClick={next == null ? null : () => this.read_content(next)}
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* View File Preview */}
        <Modal
          key="view_file_modal"
          id="view_file_modal"
          title={preview_file_name}
          className="modal-lg"
        >
          <div className="modal-body text-center p-4 mb-3">
            <div className="mt-4">
              {preview_file_type === "image" ? (
                <img
                  src={preview_file_path}
                  alt={preview_file_name}
                  key={preview_file_name}
                  className="playlist_preview_image"
                />
              ) : (
                ""
              )}
              {preview_file_type === "video" ? (
                <video
                  controls
                  className="playlist_preview_image"
                  key={preview_file_name}
                >
                  <source src={preview_file_path} />
                </video>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal>

        {/* Upload Clips/Banners */}
        <Modal
          key="add_clips_or_banners_modal"
          className="modal-lg"
          id="add_clips_or_banners_modal"
          title="Upload Clips/Banners"
        >
          <form action="" onSubmit={this.upload}>
            <Previews />
            <div className="text-right p-3">
              <button
                type="submit"
                className="btn btn-primary btn-sm px-3 rounded-custom mr-1"
              >
                Upload {uplaod_button_loader}
              </button>
              <button
                type="submit"
                className="btn btn-danger btn-sm px-3 rounded-custom"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </Modal>

        {/* Add text scroller modal */}
        <Modal
          key="add_text_scroller_modal"
          id="add_text_scroller_modal"
          title="Add Text Scroller"
        >
          <form action="" onSubmit={this.create_scroller}>
            <div className="modal-body p-4">
              <div className="row g-3 mb-4">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="color">Text Color</label>
                    <input
                      type="color"
                      name="color"
                      defaultValue="#FFFFFF"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="bg_color">Background Color</label>
                    <input
                      type="color"
                      name="bg_color"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="bg_color">Font Size</label>
                    <input
                      type="number"
                      name="font_size"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control rounded-custom"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Text Scroller</label>
                <textarea
                  name="content"
                  cols="30"
                  rows="5"
                  className="form-control"
                  required
                ></textarea>
              </div>
            </div>
            <div className="modal-footer border-0 pb-4">
              <button
                type="submit"
                className="btn btn-primary btn-sm rounded-custom pl-3 pr-3"
              >
                Add Scroller {loader}
              </button>

              <button
                type="reset"
                className="btn btn-danger btn-sm lift rounded-custom pl-3 pr-3"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </Modal>
      </main>
    );
  }
}
