import React, { PureComponent } from "react";

export default class Settings extends PureComponent {
  componentDidMount = () => {
    document.title = "Changelogs - Wilyer Signage";
  };
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Wilyer Signage Updates</h3>
          </div>
        </div>
        <div className="row justify-content-start mt-4 mb-5">
          <div className="col-md-8">
            <div className="card p-4">
              <div className="header pb-4">
                <h4 className="header-title"> Changelogs </h4>
              </div>
              <h5 className="d-inline-block">
                <span className="badge bg-info mt-n1 rounded-custom">
                  v2.0 - Current
                </span>
              </h5>
              <span className="text-muted ml-2"> – January 01, 2021</span>
              <ul className="mb-5">
                <li>New Fresh & Light Speed Design</li>
                <li>High Speed backend API</li>
                <li>Wilyer Signage V2.0 app released</li>
                <li>Security improvements</li>
                <li>Large files uploads available upto 2GB</li>
                <li>Content Analytic feature added</li>
                <li>Screen Analytics feature added</li>
                <li>Screen Location added</li>
                <li>Redesigned Support & Help & FAQ system</li>
                <li>Dark mode/Adaptive Dark mode now available</li>
                <li>More simplified UI/UX than previous releases</li>
                <li>Screen playlists & scrollers preview now available</li>
              </ul>
              <h5 className="d-inline-block">
                <span className="badge bg-info mt-n1 rounded-custom">v1.2</span>
              </h5>
              <span className="text-muted ml-2"> – March 21st, 2020</span>
              <ul className="mb-5">
                <li>Purchase License with new screen now available.</li>
                <li>Wilyer Partner Program Launched.</li>
                <li>Pricing table now available.</li>
                <li>Quick switch available between Wilyer services now</li>
                <li>Added new features.</li>
                <li>Website Speed improved.</li>
                <li>Website Design improved.</li>
                <li>Other small changes and improvements.</li>
              </ul>
              <h5 className="d-inline-block">
                <span className="badge bg-info mt-n1 rounded-custom">v1.1</span>
              </h5>{" "}
              <span className="text-muted ml-2"> – February 11th, 2020</span>
              <ul className="mb-5">
                <li>Added new dashboard variations.</li>
                <li>App v1.1 released.</li>
                <li>Enhanced content-library layout makes it lighter.</li>
                <li>Added new three media Layout in screens.</li>
                <li>Added assign playlist to selected screens feature.</li>
                <li>Added new columns in screen's table.</li>
                <li>Added FAQs.</li>
                <li>Added changelog for versions.</li>
                <li>Added screen name change feature.</li>
                <li>Other small changes and improvements.</li>
              </ul>
              <h5 className="d-inline-block">
                <span className="badge bg-info mt-n1 rounded-custom">v1.0</span>
              </h5>
              <span className="text-muted ml-2"> – December 25th, 2019</span>
              <ul>
                <li>
                  Initial release of Wilyer Signage! Lots more coming soon
                  though 😁
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
