module.exports = {
  getCovertedAnalyticsDuration: (sec) => {
    var h = parseInt(sec / 3600);
    var m = parseInt((sec - h * 3600) / 60);
    var s = sec - m * 60;
    if (h !== 0) {
      return h + " hr, " + m + " min";
    } else if (m !== 0) {
      return m + " min, " + s + " sec";
    } else {
      return s + " sec";
    }
  },
  timeConvert: (time) => {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  },
};
