import React, { PureComponent } from "react";
import Axios from "axios";
import { config, server } from "../../routes/env";

export default class Dashboard extends PureComponent {
  state = {
    data: {},
    users: "-",
    screens: "-",
    licenses: "-",
  };

  componentDidMount = async () => {
    document.title = "Dashbaord - Signage Cloud Enterprise";
    await Axios.get(server + "/enterprise/dashboard", config).then((rsp) => {
      this.setState({
        data: rsp.data,
        users: rsp.data.users,
        screens: rsp.data.screens,
        licenses: rsp.data.licenses,
      });
    });
  };

  render() {
    const { users, screens, data, licenses } = this.state;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Dashboard</h3>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          {/* Your clients */}
          <div className="col-md-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3 className="clients">{users}</h3>
                    <h5>Sub-Users</h5>
                    <p className="small text-muted mb-0">
                      <time>
                        Your{" "}
                        <b>
                          {users <= 1
                            ? users + " sub-user is "
                            : users + " sub-users are "}
                        </b>
                        on signage.
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Total Screens */}
          <div className="col-md-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3>
                      <span className="text-success">{data.onlineScreens}</span>{" "}
                      /{" "}
                      <span className="text-danger">{data.offlineScreens}</span>
                    </h3>
                    <h5>Screens</h5>
                    <p className="small text-muted mb-0">
                      <time>
                        Your{" "}
                        <b>
                          <b>
                            {users <= 1
                              ? users + " sub-user "
                              : users + " sub-users "}
                          </b>
                        </b>{" "}
                        have <b>{screens} screens</b> on signage.
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Total Licenses Are Left */}
          <div className="col-md-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3 className="clients">{licenses}</h3>
                    <h5>Signage Licenses Left</h5>
                    <p className="small text-muted mb-0">
                      <time>
                        <b>
                          {licenses <= 1
                            ? licenses + " signage license is "
                            : licenses + " signage licenses are"}
                        </b>{" "}
                        left in your account
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
