import React, { PureComponent } from "react";
import Axios from "axios";
import Spinner from "../../components/Spinner";
import { server, config } from "../../routes/env";
import { Notification } from "react-notification";

export default class Playlists_To_Screens extends PureComponent {
  state = {
    orientation: "PORTRAIT",
    layouts: [],
    playlists: [],
    scrollers: [],
    screens: [],
    selectedScreens: [],

    is_screen_loaded: false,
    playlist_status: false,
    scroller_status: false,

    current_layout: null,

    loader: "",
    message: "",
    notification: false,
    search: "",
  };

  componentDidMount = async () => {
    document.title = "Assign Playlists To Screens - Signage Cloud";
    await Axios.get(server + "/signage/playlist/read?records_all", config).then(
      (rsp) => {
        this.setState({
          playlists: rsp.data.playlists,
        });
      }
    );

    await Axios.get(
      server + "/signage/content/text/read?records_all",
      config
    ).then((rsp) => {
      this.setState({
        scrollers: rsp.data.scrollers,
      });
    });

    this.read_layouts();
    this.read_screens();
  };

  read_layouts = async () => {
    await Axios.get(
      server +
        "/signage/screen/layout/read?orientation=" +
        this.state.orientation,
      config
    ).then((rsp) => {
      this.setState({
        layouts: rsp.data.layouts,
        playlist_status: rsp.data.layouts.length > 0 ? false : true,
        scroller_status: rsp.data.layouts.length > 0 ? false : true,
      });
    });
  };

  read_screens = async () => {
    await Axios.get(
      server + "/signage/screen/read?orientation=" + this.state.orientation,
      config
    ).then((rsp) => {
      this.setState({
        screens: rsp.data.screens,
        is_screen_loaded: true,
      });
    });
  };

  onOrientationChange = async (event) => {
    await this.setState({
      orientation: event.target.value,
    });
    await this.read_layouts();
    await this.read_screens();
  };

  onLayoutChange = (event) => {
    this.setState({
      current_layout: event.target.value,
    });
  };

  onPlaylistChange = async (event) => {
    var layout = this.state.layouts.filter(
      (data) => this.state.current_layout === data.id
    )[0];
    var checkedValue = 0;
    var playlist = document.getElementsByClassName("playlists");
    for (var i = 0; playlist[i]; ++i) {
      if (playlist[i].checked) {
        checkedValue++;
        if (checkedValue > layout.playlist) {
          playlist[i].checked = false;
          break;
        }
      }
    }
  };

  onScrollerChange = async (event) => {
    var layout = this.state.layouts.filter(
      (data) => this.state.current_layout === data.id
    )[0];
    var checkedValue = 0;
    var scroller = document.getElementsByClassName("scrollers");
    for (var i = 0; scroller[i]; ++i) {
      if (scroller[i].checked) {
        checkedValue++;
        if (checkedValue > layout.scroller) {
          scroller[i].checked = false;
          break;
        }
      }
    }
  };

  onSelectAllChange = async (event) => {
    var screen = document.getElementsByClassName("screens");
    for (var i = 0; screen[i]; ++i) {
      if (event.target.checked) screen[i].checked = true;
      else screen[i].checked = false;
    }
  };

  update = async (event) => {
    this.setState({
      loader: <Spinner />,
    });
    var screens = this.state.selectedScreens;

    var scrollers = [];
    var scroller = document.getElementsByClassName("scrollers");
    for (let i = 0; scroller[i]; ++i) {
      if (scroller[i].checked) scrollers.push(scroller[i].value);
    }

    var playlists = [];
    var playlist = document.getElementsByClassName("playlists");
    for (let i = 0; playlist[i]; ++i) {
      if (playlist[i].checked) playlists.push(playlist[i].value);
    }

    var layout = this.state.layouts.filter(
      (data) => this.state.current_layout === data.id
    )[0];

    if (layout === undefined) {
      this.setState({
        message: "Please select a layout",
        notification: true,
        loader: "",
      });
      return;
    }

    if (playlists.length < layout.playlist) {
      this.setState({
        message: "Please select required playlists",
        notification: true,
        loader: "",
      });
      return;
    }
    if (scrollers.length < layout.scroller) {
      this.setState({
        message: "Please select required scrollers",
        notification: true,
        loader: "",
      });
      return;
    }
    if (screens.length === 0) {
      this.setState({
        message: "Please select a screen",
        notification: true,
        loader: "",
      });
      return;
    }

    const params = {
      screens,
      playlists,
      scrollers,
      layout: layout.id,
    };

    Axios.put(server + "/signage/screen/update_multiple", params, config)
      .then((rsp) => {
        this.setState({
          loader: "",
          notification: true,
          message: rsp.data.detail,
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  selectScreen = async (e, id) => {
    console.log(e.target.checked, id);
    if (e.target.checked) {
      this.setState({
        selectedScreens: this.state.selectedScreens.concat(id),
      });
    } else {
      this.setState({
        selectedScreens: this.state.selectedScreens.filter(
          (data) => data !== id
        ),
      });
    }
  };

  render() {
    var {
      layouts,
      playlists,
      screens,
      scrollers,

      playlist_status,
      scroller_status,
      current_layout,
      message,
      notification,
      loader,
      is_screen_loaded,
    } = this.state;

    playlist_status = current_layout === null ? true : false;
    scroller_status = current_layout === null ? true : false;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={4000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">
              Add Playlists To Screens
            </h3>
          </div>
        </div>
        <div
          className={
            "card mx-auto shadow-sm mt-4 p-4 w-25 " +
            (is_screen_loaded ? "d-none" : "")
          }
        >
          <div className="row justify-content-center">
            <Spinner size={40} color={"#555"} />
          </div>
        </div>

        <div className="card my-4 p2s_warning">
          <div className="card-body border-0 text-center pt-4">
            <h5>Use site in desktop view for this operation</h5>
          </div>
        </div>
        <div
          className={
            is_screen_loaded ? "card my-4 p2s" : "card my-4 p2s d-none"
          }
        >
          <div className="card-body border-0">
            <div className="row">
              <div className="col-12 pb-4">
                <div className="row gy-3 mb-4 mt-2">
                  <div className="col-md-4">
                    <div className="px-3">
                      <div className="form-group">
                        <select
                          name="checkLayout"
                          id="selectLayout"
                          className="form-control rounded-custom"
                          required
                          onChange={this.onOrientationChange}
                          defaultValue="PORTRAIT"
                        >
                          <option value="LANDSCAPE">Landscape</option>
                          <option value="PORTRAIT">Portrait</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="px-3">
                      <div className="form-group">
                        <select
                          name="checkLayout"
                          id="selectLayout"
                          className="form-control rounded-custom"
                          required
                          onChange={this.onLayoutChange}
                        >
                          <option value="">Select Layout</option>
                          {layouts.map((data, index) => (
                            <option value={data.id} key={index}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="px-3">
                      <input
                        type="checkbox"
                        className="form-check-input mt-2"
                        name="checkall"
                        id="checkall"
                        onChange={this.onSelectAllChange}
                      />
                      <label
                        className="custom-control-label mt-1 ml-2"
                        htmlFor="checkall"
                      >
                        Select All Screen
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12 wilyer_p2s">
                    <div className="px-3">
                      <h6 className="text-muted">Playlist</h6>
                      {playlists.map((data, index) => (
                        <div
                          className="card shadow-sm border my-3 p-3 py-2"
                          key={index}
                        >
                          <div className="form-check">
                            <label htmlFor="playlist" className="pl-1 mt-1">
                              {data.name}
                            </label>
                            <input
                              className="form-check-input mt-2 playlists"
                              type="checkbox"
                              name="playlist"
                              defaultValue={data.id}
                              onChange={this.onPlaylistChange}
                              disabled={playlist_status}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 wilyer_p2s mt-4">
                    <div className="px-3">
                      <h6 className="text-muted">Scrollers</h6>
                      {scrollers.map((data, index) => (
                        <div
                          className="card shadow-sm border my-3 p-3 py-2"
                          key={index}
                        >
                          <div className="form-check">
                            <label htmlFor="playlist" className="pl-1 mt-1">
                              {data.title}
                            </label>
                            <input
                              className="form-check-input mt-2 scrollers"
                              type="checkbox"
                              name="playlist"
                              defaultValue={data.id}
                              disabled={scroller_status}
                              onChange={this.onScrollerChange}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-12 wilyer_p2s_screen">
                  <div className="px-3">
                    <h6 className="text-muted">Screens</h6>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="form-control"
                      placeholder="Search by name"
                      onChange={(e) =>
                        this.setState({
                          search: e.target.value,
                        })
                      }
                    />
                    {screens
                      .filter((s) =>
                        s.name
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      )
                      .map((data, index) => (
                        <div
                          className="card shadow-sm border my-3 p-3 py-2"
                          key={index}
                        >
                          <div className="form-check">
                            <label htmlFor="playlist" className="pl-1 mt-1">
                              {data.name}
                            </label>
                            <input
                              className="form-check-input mt-2 screens"
                              type="checkbox"
                              name="screen"
                              checked={this.state.selectedScreens.includes(
                                data.id
                              )}
                              onChange={(e) => this.selectScreen(e, data.id)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer bg-transparent text-right pr-4 py-3">
            <button className="btn btn-sm btn-primary" onClick={this.update}>
              Apply Changes {loader}
            </button>
          </div>
        </div>
      </main>
    );
  }
}
